import { axiosClient } from '@src/domain/api/x10/common'
import { type StarkTransfer } from '@src/domain/starkex/stark-transfer'

import { TransferEnvelopedSchema } from './transfer.schema'

export const transfer = async (transfer: StarkTransfer) => {
  const { data } = await axiosClient.post<unknown>('/api/v1/user/transfer', transfer)

  return TransferEnvelopedSchema.parse(data)
}
