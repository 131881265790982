import * as z from 'zod'

import { addRestEnvelope } from '../../utils/add-rest-envelope'
import { zodDecimal } from '../../utils/zod'
import { HexStringSchema } from '../common'

export const LeaderboardCurrentUserSchema = z
  .object({
    rank: z.number().optional(),
    pnlCollateral: zodDecimal().optional(),
    pnlPct: zodDecimal().optional(),
    volume: zodDecimal().optional(),
  })
  .default({})

export const LeaderboardTopUsersSchema = z.array(
  z.object({
    rank: z.number(),
    wallet: HexStringSchema,
    pnlCollateral: zodDecimal(),
    pnlPct: zodDecimal(),
    volume: zodDecimal(),
  }),
)

export const EnvelopedLeaderboardCurrentUserSchema = addRestEnvelope(
  LeaderboardCurrentUserSchema,
)
export const EnvelopedLeaderboardTopUsersSchema = addRestEnvelope(
  LeaderboardTopUsersSchema,
)

export const LeaderboardPeriodSchema = z.enum(['DAY', 'WEEK', 'MONTH', 'ALL'])

export type LeaderboardCurrentUser = z.infer<typeof LeaderboardCurrentUserSchema>
export type LeaderboardTopUsers = z.infer<typeof LeaderboardTopUsersSchema>
export type LeaderboardPeriod = z.infer<typeof LeaderboardPeriodSchema>

export type EnvelopedLeaderboardCurrentUser = z.infer<
  typeof EnvelopedLeaderboardCurrentUserSchema
>
export type EnvelopedLeaderboardTopUsers = z.infer<
  typeof EnvelopedLeaderboardTopUsersSchema
>
