import type { StateStorage } from 'zustand/middleware'

import { invariant } from '@x10/lib-core/utils'

export const createAccountStorage = () => {
  let accountId: string | undefined = undefined

  const storage: StateStorage = {
    getItem: (name: string) => {
      invariant(accountId, 'accountId')

      return localStorage.getItem(`${name}-${accountId}`)
    },
    setItem: (name: string, value: string) => {
      invariant(accountId, 'accountId')

      localStorage.setItem(`${name}-${accountId}`, value)
    },
    removeItem: (name: string) => {
      invariant(accountId, 'accountId')

      localStorage.removeItem(`${name}-${accountId}`)
    },
  }

  return {
    storage,

    setAccountId(id: string | undefined) {
      accountId = id
    },
  }
}
