import * as z from 'zod'

import { addRestEnvelope } from '@src/domain/api/utils/add-rest-envelope'
import { zodLong } from '@src/domain/api/utils/zod'
import { HexStringSchema } from '@src/domain/api/x10/common'

const AssetSchema = z
  .object({
    id: zodLong(),
    name: z.string(),
    symbol: z.string(),
    precision: z.number(),
    isActive: z.boolean(),
    isCollateral: z.boolean(),
    starkexId: HexStringSchema,
    starkexResolution: zodLong(),
    l1Id: z.string(),
    l1Resolution: zodLong(),
    version: z.number(),
  })
  .transform((value) => ({
    ...value,
    l1Id: value.l1Id ? HexStringSchema.parse(value.l1Id) : undefined,
  }))

export const EnvelopedAssetsSchema = addRestEnvelope(AssetSchema.array())
