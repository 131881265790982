import {
  axiosClient,
  withActiveAccount,
  type MarketName,
  type PositionSide,
} from '@src/domain/api/x10/common'

import { getRealisedPnlSchema } from './realised-pnl.schema'

export const getRealisedPnl = async (
  { marketsNames, side }: { marketsNames?: MarketName[]; side?: PositionSide } = {},
  accountId?: string,
) => {
  const { data } = await axiosClient.get('/api/v1/user/positions/history', {
    params: {
      market: marketsNames,
      side,
    },
    headers: withActiveAccount(accountId),
  })

  return getRealisedPnlSchema.parse(data)
}
