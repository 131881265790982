import * as z from 'zod'

import { addRestEnvelope } from '@src/domain/api/utils/add-rest-envelope'
import { zodDecimal, zodLong } from '@src/domain/api/utils/zod'

import {
  MarketNameSchema,
  OrderSideSchema,
  OrderStatusSchema,
  OrderTypeSchema,
} from '../../common'

const TriggerPriceTypeSchema = z.enum(['UNKNOWN', 'MARK', 'INDEX', 'LAST'])
const ExecutionPriceTypeSchema = z.enum(['UNKNOWN', 'MARKET', 'LIMIT'])
const OrderPriceTypeSchema = z.enum(['UNKNOWN', 'MARKET', 'LIMIT'])
const TpSlTypeSchema = z.enum(['UNKNOWN', 'ORDER', 'POSITION'])
const TriggerDirectionTypeSchema = z.enum(['UP', 'DOWN', 'UNKNOWN'])

const OrderConditionalTriggerSchema = z.object({
  triggerPrice: zodDecimal(),
  triggerPriceType: TriggerPriceTypeSchema,
  executionPriceType: ExecutionPriceTypeSchema,
  direction: TriggerDirectionTypeSchema,
})

const OrderOrderTpSlTriggerSchema = z.object({
  triggerPrice: zodDecimal(),
  triggerPriceType: TriggerPriceTypeSchema,
  price: zodDecimal(),
  priceType: OrderPriceTypeSchema,
  starkExOrder: z.string().optional(),
  starkExSignature: z.string().optional(),
})

export const UserOrderSchema = z.object({
  id: zodLong(),
  accountId: zodLong(),
  externalId: z.string(),
  market: MarketNameSchema,
  type: OrderTypeSchema,
  side: OrderSideSchema,
  status: OrderStatusSchema,
  statusReason: z.string().optional(),
  // Price of the order. If it's a TP/SL order, it will be null.
  price: zodDecimal().optional(),
  averagePrice: zodDecimal().optional(),
  qty: zodDecimal(),
  filledQty: zodDecimal().optional(),
  trigger: OrderConditionalTriggerSchema.optional(),
  tpSlType: TpSlTypeSchema.optional(),
  takeProfit: OrderOrderTpSlTriggerSchema.optional(),
  stopLoss: OrderOrderTpSlTriggerSchema.optional(),
  reduceOnly: z.boolean(),
  postOnly: z.boolean(),
  createdTime: z.number(),
  expiryTime: z.number().optional(),
  prevOrderId: z.string().optional(),
})

export type UserOrder = z.infer<typeof UserOrderSchema>
export type OrderPriceType = z.infer<typeof OrderPriceTypeSchema>
export const EnvelopedUserOrdersSchema = addRestEnvelope(z.array(UserOrderSchema))

export type EnvelopedUserOrders = z.infer<typeof EnvelopedUserOrdersSchema>
