import * as z from 'zod'

import { addRestEnvelope } from '@src/domain/api/utils/add-rest-envelope'
import { zodDecimal } from '@src/domain/api/utils/zod'
import { MarketNameSchema } from '@src/domain/api/x10/common'

const FeesSchema = z.object({
  market: MarketNameSchema,
  makerFeeRate: zodDecimal(),
  takerFeeRate: zodDecimal(),
})

export const EnvelopedFeesSchema = addRestEnvelope(z.array(FeesSchema))

export type Fees = z.infer<typeof FeesSchema>
