import type {
  CryptoCurrencyCollateralCode,
  CryptoCurrencySyntheticCode,
} from '@x10/lib-core/config'
import { invariant } from '@x10/lib-core/utils'

import type { MarketName } from '@src/domain/api/x10/common'

const MIN_CODE_LENGTH = 2

export const parseMarketName = (marketName: MarketName) => {
  const codes = marketName.split('-')

  invariant(
    codes.length === 2 && codes.every((code) => code.length >= MIN_CODE_LENGTH),
    `\`marketName\` must be in format \`XXX-YYY\`, received: ${marketName}`,
  )

  return {
    syntheticCode: codes[0] as CryptoCurrencySyntheticCode,
    collateralCode: codes[1] as CryptoCurrencyCollateralCode,
  }
}
