import type { HexString } from '@x10/lib-core/types'

import { axiosClient } from '@src/domain/api/x10/common'
import { SubAccountSchema } from '@src/domain/api/x10/trading/account/subaccount.schema'

export const createSubAccount = async (args: {
  description: string
  l2Key: HexString
  l1Signature: HexString
  l2Signature: {
    r: HexString
    s: HexString
  }
  accountCreation: {
    accountIndex: number
    wallet: string
    time: Date
  }
}) => {
  const { data } = await axiosClient.post<unknown>('/auth/subaccount', {
    description: args.description,
    l2Key: args.l2Key,
    l1Signature: args.l1Signature,
    l2Signature: args.l2Signature,
    accountCreation: {
      accountIndex: args.accountCreation.accountIndex,
      wallet: args.accountCreation.wallet,
      tosAccepted: true,
      action: 'CREATE_SUB_ACCOUNT',
      time: args.accountCreation.time.toISOString(),
    },
  })
  return SubAccountSchema.parse(data)
}
