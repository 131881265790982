import { axiosClient, withActiveAccount } from '@src/domain/api/x10/common'
import {
  EnvelopedClaimResponseSchema,
  EnvelopedClaimSchema,
  EnvelopedNextClaimSchema,
} from '@src/domain/api/x10/trading/account/claim-funds.schema'

export const claimFunds = async (accountId: string | undefined) => {
  const { data } = await axiosClient.post<unknown>('/api/v1/user/claim', undefined, {
    headers: withActiveAccount(accountId),
  })

  return EnvelopedClaimResponseSchema.parse(data)
}

export const getClaimById = async ({ id }: { id: string }) => {
  const { data } = await axiosClient.get<unknown>(`/api/v1/user/claim/${id}`)

  return EnvelopedClaimSchema.parse(data)
}

export const getNextClaim = async () => {
  const { data } = await axiosClient.get<unknown>(`/api/v1/user/nextclaim`)

  return EnvelopedNextClaimSchema.parse(data)
}
