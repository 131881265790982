import { sortBy } from 'lodash'
import { useSuspenseQuery } from '@tanstack/react-query'

import { getCommonPeriodicQueryConfig } from '@src/domain/api/utils/get-common-periodic-query-config'
import { removeRestEnvelopeSuspense } from '@src/domain/api/utils/remove-rest-envelope'
import { API, type CachedMarket, type EnvelopedMarkets } from '@src/domain/api/x10'
import type { MarketName } from '@src/domain/api/x10/common'
import { QueryKey } from '@src/domain/trade/constants'

const filterVisibleMarkets = (data: EnvelopedMarkets) => {
  data.data = data.data.filter((market) => market.visibleOnUi)

  return data
}

export const useSuspendedCachedMarkets = ({
  marketsNames,
}: { marketsNames?: MarketName[] } = {}) => {
  const sortedMarketsNames = sortBy(marketsNames)

  const { data } = useSuspenseQuery({
    queryKey: [QueryKey.MarketsCached, sortedMarketsNames],
    queryFn: () => {
      return API.trading.marketsInfo.getMarkets({ marketsNames: sortedMarketsNames })
    },
    select: filterVisibleMarkets,
    staleTime: Number.POSITIVE_INFINITY,
    retry: false,
  })

  return { data: removeRestEnvelopeSuspense(data) as CachedMarket[] }
}

export const useSuspendedMarkets = ({
  marketsNames,
}: { marketsNames?: MarketName[] } = {}) => {
  const sortedMarketsNames = sortBy(marketsNames)
  const { data } = useSuspenseQuery({
    queryKey: [QueryKey.Markets, sortedMarketsNames],
    queryFn: () => {
      return API.trading.marketsInfo.getMarkets({ marketsNames: sortedMarketsNames })
    },
    select: filterVisibleMarkets,
    ...getCommonPeriodicQueryConfig(),
  })

  return { data: removeRestEnvelopeSuspense(data) }
}
