import { create } from 'zustand'

import type { Decimal } from '@x10/lib-core/utils'

type MarketStatsStore = {
  lastPrice: Decimal | null

  setLastPrice(lastPrice: Decimal | null): void

  reset: VoidFunction
}

const initialState = {
  lastPrice: null,
}

export const useLatestTradesStore = create<MarketStatsStore>((set) => ({
  ...initialState,

  setLastPrice(value) {
    set({ lastPrice: value })
  },

  reset() {
    set(initialState)
  },
}))
