import * as z from 'zod'

import { addRestEnvelope } from '@src/domain/api/utils/add-rest-envelope'
import { zodDecimal } from '@src/domain/api/utils/zod'

const OpenInterestSchema = z
  .object({
    i: zodDecimal(),
    I: zodDecimal(),
    t: z.number(),
  })
  .transform((value) => ({
    openInterest: value.i,
    openInterestBase: value.I,
    timestamp: value.t,
  }))

export const EnvelopedMarketOpenInterestSchema = addRestEnvelope(
  z.array(OpenInterestSchema),
)
export type MarketOpenInterest = z.infer<typeof OpenInterestSchema>
