import axios from 'axios'

import { Decimal } from '@x10/lib-core/utils'

import { axiosClient, withActiveAccount } from '@src/domain/api/x10/common'

import { EnvelopedBalanceSchema, type EnvelopedBalance } from './balance.schema'

const ZERO_BALANCE: EnvelopedBalance = {
  status: 'OK',
  data: {
    collateralName: 'USD',
    balance: Decimal(0),
    equity: Decimal(0),
    status: 'UNKNOWN',
    availableForTrade: Decimal(0),
    availableForWithdrawal: Decimal(0),
    unrealisedPnl: Decimal(0),
    initialMargin: Decimal(0),
    marginRatio: Decimal(0),
    updatedTime: 0,
  },
}

export const getBalance = async (accountId?: string) => {
  try {
    const { data } = await axiosClient.get<unknown>('/api/v1/user/balance', {
      headers: withActiveAccount(accountId),
    })

    return EnvelopedBalanceSchema.parse(data)
  } catch (error) {
    if (
      axios.isAxiosError(error) &&
      error.response?.status === axios.HttpStatusCode.NotFound
    ) {
      return ZERO_BALANCE
    } else {
      throw error
    }
  }
}
