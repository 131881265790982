import type { CryptoCurrencyCode } from '@x10/lib-core/config'

const QueryKeyDomain = 'domain.trade'

export const QueryKey = {
  Assets: `${QueryKeyDomain}.assets`,
  FundingRate: `${QueryKeyDomain}.funding_rate`,
  Markets: `${QueryKeyDomain}.markets`,
  MarketsCached: `${QueryKeyDomain}.markets_cached`,
  MarketsStats: `${QueryKeyDomain}.markets_stats`,
  MarketStats: `${QueryKeyDomain}.market_stats`,
  OpenInterests: `${QueryKeyDomain}.open_interests`,
  Settings: `${QueryKeyDomain}.settings`,
  UserAccountInfo: `${QueryKeyDomain}.user_account_info`,
  UserAccountLeverage: `${QueryKeyDomain}.user_account_leverage`,
  UserAccountsInfo: `${QueryKeyDomain}.user_accounts_info`,
  UserAssetOperations: `${QueryKeyDomain}.user_asset_operations`,
  UserBalance: `${QueryKeyDomain}.user_balance`,
  UserBalances: `${QueryKeyDomain}.user_balances`,
  UserClientInfo: `${QueryKeyDomain}.user_client_info`,
  UserFees: `${QueryKeyDomain}.user_fees`,
  UserFunding: `${QueryKeyDomain}.user_funding`,
  UserNextClaim: `${QueryKeyDomain}.user_next_claim`,
  UserOrders: `${QueryKeyDomain}.user_orders`,
  UserOrdersHistory: `${QueryKeyDomain}.user_orders_history`,
  UserPositions: `${QueryKeyDomain}.user_positions`,
  UserRealisedPnl: `${QueryKeyDomain}.user_realised_pnl`,
  UserReferrals: `${QueryKeyDomain}.user_referrals`,
  UserTrades: `${QueryKeyDomain}.user_trades`,
  WithdrawalFee: `${QueryKeyDomain}.withdrawal_fee`,
  WithdrawalRemainingLimit: `${QueryKeyDomain}.withdrawals_remaining_limit`,
  Withdrawals: `${QueryKeyDomain}.withdrawals`,
  WithdrawalTarget: `${QueryKeyDomain}.withdrawal_target`,
} as const

export const QUERIES_TO_DROP_ON_LOGOUT = [
  QueryKey.UserAccountInfo,
  QueryKey.UserAccountLeverage,
  QueryKey.UserAccountsInfo,
  QueryKey.UserBalance,
  QueryKey.UserFees,
  QueryKey.UserFunding,
  QueryKey.UserOrders,
  QueryKey.UserOrdersHistory,
  QueryKey.UserPositions,
  QueryKey.UserRealisedPnl,
  QueryKey.UserTrades,
  QueryKey.Withdrawals,
  QueryKey.WithdrawalRemainingLimit,
  QueryKey.WithdrawalFee,
  QueryKey.UserClientInfo,
] as const

export const DEFAULT_ACCOUNT_COLLATERAL_CODE = 'USD' as CryptoCurrencyCode

export const PINNED_MARKETS_LIMIT = 5
