import * as z from 'zod'

export const LoginSchema = z.enum(['Logged in'])
export const LogoutSchema = z.enum(['Logged out'])
export const RegisterSchema = z.enum(['Registered', 'Logged in'])
export const InfoSchema = z.object({
  accessExpired: z.boolean(),
  refreshExpired: z.boolean(),
})
export const RefreshSchema = z.enum(['refreshed'])
export const WsTokenSchema = z.string()

export type Login = z.infer<typeof LoginSchema>
export type Logout = z.infer<typeof LogoutSchema>
export type Register = z.infer<typeof RegisterSchema>
export type Info = z.infer<typeof InfoSchema>
export type Refresh = z.infer<typeof RefreshSchema>
export type WsToken = z.infer<typeof WsTokenSchema>
