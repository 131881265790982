import * as z from 'zod'

import type { MarketAssets } from '@x10/lib-core/types'

import { addRestEnvelope } from '@src/domain/api/utils/add-rest-envelope'
import { zodDecimal, zodDecimalOrZero, zodLong } from '@src/domain/api/utils/zod'
import { HexStringSchema, MarketNameSchema } from '@src/domain/api/x10/common'
import { parseMarketName } from '@src/domain/core/utils/parse-market-name'

export const MarketStatsSchema = z
  .object({
    dailyVolume: zodDecimal(),
    dailyVolumeBase: zodDecimal(),
    dailyPriceChange: zodDecimal(),
    dailyPriceChangePercentage: zodDecimal(),
    dailyLow: zodDecimal(),
    dailyHigh: zodDecimal(),
    lastPrice: zodDecimal(),
    askPrice: zodDecimal(),
    bidPrice: zodDecimal(),
    markPrice: zodDecimal(),
    indexPrice: zodDecimal(),
    fundingRate: zodDecimal(),
    nextFundingRate: zodLong(),
    openInterest: zodDecimal(),
    openInterestBase: zodDecimal(),
  })
  .transform((value) => {
    return {
      ...value,
      midPrice: value.askPrice.plus(value.bidPrice).div(2),
    }
  })

export const MarketsStatsSchema = z
  .object({
    market: MarketNameSchema,
    stats: MarketStatsSchema,
  })
  .array()

const RiskFactorConfigSchema = z
  .object({
    upperBound: zodDecimal(),
    riskFactor: zodDecimal(),
  })
  .array()

const TradingConfigSchema = z.object({
  minOrderSize: zodDecimal(),
  minOrderSizeChange: zodDecimal(),
  minPriceChange: zodDecimal(),
  maxMarketOrderValue: zodDecimal(),
  maxLimitOrderValue: zodDecimal(),
  maxPositionValue: zodDecimal(),
  maxLeverage: zodDecimal(),
  maxNumOrders: zodDecimal(),
  limitPriceCap: zodDecimal(),
  limitPriceFloor: zodDecimal(),
  riskFactorConfig: RiskFactorConfigSchema,
  /**
   * @deprecated
   */
  maxMarketOrderSize: zodDecimalOrZero(),
  /**
   * @deprecated
   */
  maxLimitOrderSize: zodDecimalOrZero(),
  /**
   * @deprecated
   */
  maxPositionSize: zodDecimalOrZero(),
  /**
   * @deprecated
   */
  baseRiskLimit: zodDecimalOrZero(),
  /**
   * @deprecated
   */
  riskStep: zodDecimalOrZero(),
  /**
   * @deprecated
   */
  initialMarginFraction: zodDecimalOrZero(),
  /**
   * @deprecated
   */
  incrementalInitialMarginFraction: zodDecimalOrZero(),
})

const L2ConfigSchema = z.object({
  type: z.string(),
  collateralId: HexStringSchema,
  collateralResolution: z.number(),
  syntheticId: HexStringSchema,
  syntheticResolution: z.number(),
})

export const MarketSchema = z
  .object({
    name: MarketNameSchema,
    category: z.string(),
    assetName: z.string(),
    assetPrecision: z.number(),
    collateralAssetName: z.string(),
    collateralAssetPrecision: z.number(),
    active: z.boolean(),
    visibleOnUi: z.boolean().default(true),
    /**
     * Should not be used with `useMarketsCached` hook as we're caching markets on the client.
     * Use separate API endpoint to get market stats.
     */
    marketStats: MarketStatsSchema,
    tradingConfig: TradingConfigSchema,
    l2Config: L2ConfigSchema,
  })
  .transform((value) => {
    const assetsCodes = parseMarketName(value.name)
    const assets: MarketAssets = {
      synthetic: {
        code: assetsCodes.syntheticCode,
        /**
         * Synthetic asset precision (10, 0.1, 0.01, 0.001, etc.)
         */
        precision: value.tradingConfig.minOrderSizeChange,
      },
      collateral: {
        code: assetsCodes.collateralCode,
        /**
         * Collateral asset precision (10, 0.1, 0.01, 0.001, etc.)
         */
        precision: value.tradingConfig.minPriceChange,
      },
    }

    return {
      ...value,
      assets,
    }
  })

export const EnvelopedMarketsSchema = addRestEnvelope(z.array(MarketSchema))
export const EnvelopedMarketStatsSchema = addRestEnvelope(MarketStatsSchema)
export const EnvelopedMarketsStatsSchema = addRestEnvelope(MarketsStatsSchema)

export type EnvelopedMarkets = z.infer<typeof EnvelopedMarketsSchema>
export type Market = z.infer<typeof MarketSchema>
export type CachedMarket = Omit<Market, 'marketStats'>
export type MarketL2Config = z.infer<typeof L2ConfigSchema>
export type MarketTradingConfig = z.infer<typeof TradingConfigSchema>
export type MarketStats = z.infer<typeof MarketStatsSchema>
export type RiskFactorConfig = z.infer<typeof RiskFactorConfigSchema>
