import * as amplitude from '@amplitude/analytics-browser'
import { type EnrichmentPlugin, type Event } from '@amplitude/analytics-types'

import { APP_TITLE } from '../config/static'

const PAGE_TITLE_EVENT_PROP = '[Amplitude] Page Title'

class NormaliseEventsPlugin implements EnrichmentPlugin {
  name = 'normalise-events-plugin'
  type = 'enrichment' as const

  async execute(event: Event): Promise<Event | null> {
    const eventProps = event.event_properties as Record<string, string> | undefined

    if (eventProps && eventProps[PAGE_TITLE_EVENT_PROP]) {
      eventProps[PAGE_TITLE_EVENT_PROP] = APP_TITLE
    }

    return event
  }
}

export const events = {
  startTradingButtonClick: () => ({
    type: 'Start Trading Button Click',
  }),
  walletConnected: (props: { connectorId: string; connectorType: string }) => ({
    type: 'Wallet Connected',
    props: {
      x10_wallet_connector_id: props.connectorId,
      x10_wallet_connector_type: props.connectorType,
    },
  }),
}

export const init = (apiKey: string) => {
  amplitude.add(new NormaliseEventsPlugin())
  amplitude.init(apiKey, {
    autocapture: true,
    // Override the default minimum length of 5 characters
    // https://developers.amplitude.com/docs/http-api-v2#device-ids-and-user-ids-minimum-length
    minIdLength: 4,
  })
}

export const setUserId = (userId: string | undefined) => {
  amplitude.setUserId(userId)
}

export const trackEvent = (event: {
  type: string
  props?: Record<string, string>
  context?: string
}) => {
  amplitude.track(event.type, {
    ...event.props,
    x10_event_context: event.context,
  })

  if (window.gtag) {
    // https://github.com/vercel/next.js/issues/61703
    window.gtag('event', event.type)
  }
}
