import {
  axiosClient,
  withActiveAccount,
  type PositionSide,
} from '@src/domain/api/x10/common'

import { getPositionsSchema } from './positions.schema'

export const getPositions = async (
  { marketsNames, side }: { marketsNames?: string[]; side?: PositionSide } = {},
  accountId?: string,
) => {
  const { data } = await axiosClient.get('/api/v1/user/positions', {
    params: {
      market: marketsNames,
      side,
    },
    headers: withActiveAccount(accountId),
  })

  return getPositionsSchema.parse(data)
}
