import { isNull } from 'lodash'

import { getStorageKey } from './get-storage-key'
import type { LocalStorageKey } from './types'

export const appLocalStorage = {
  getItem<T = unknown>(key: LocalStorageKey) {
    const item = this._getItem(key)

    return isNull(item) ? item : (item.payload as T)
  },

  getStoreItem<T = unknown>(key: LocalStorageKey) {
    const item = this._getItem(key)

    return isNull(item) ? item : (item.state as T)
  },

  setItem(key: LocalStorageKey, payload: unknown) {
    try {
      localStorage.setItem(getStorageKey(key), JSON.stringify({ payload }))

      return true
    } catch (e) {
      return false
    }
  },

  removeItem(key: LocalStorageKey) {
    try {
      localStorage.removeItem(getStorageKey(key))

      return true
    } catch (e) {
      return false
    }
  },

  clear() {
    localStorage.clear()
  },

  _getItem(key: LocalStorageKey) {
    try {
      const raw = localStorage.getItem(getStorageKey(key))

      if (isNull(raw)) {
        return raw
      }

      return JSON.parse(raw)
    } catch (e) {
      return null
    }
  },
}
