import { axiosClient, withActiveAccount } from '@src/domain/api/x10/common'

import {
  EnvelopedUserAssetOperationsSchema,
  type AssetOperationStatus,
  type AssetOperationType,
} from './asset-operations.schema'

export type GetAssetOperationsArgs = {
  type?: AssetOperationType[]
  status?: AssetOperationStatus[]
  startTime?: number
  endTime?: number
  limit?: number
  cursor?: number
}

export const getAssetOperations = async (
  args: GetAssetOperationsArgs = {},
  accountId?: string,
) => {
  const { data } = await axiosClient.get<unknown>('/api/v1/user/assetOperations', {
    params: args,
    headers: withActiveAccount(accountId),
  })

  return EnvelopedUserAssetOperationsSchema.parse(data)
}
