import { getClientConfig } from '@src/domain/core/config/env'

import { type CandlePriceSource, type MarketName, type X10Interval } from '../common'

export const candles = (
  source: CandlePriceSource,
  marketName: MarketName,
  interval: X10Interval,
) => {
  return `wss://${getClientConfig().restApiHost}/stream.x10.exchange/v1/candles/${marketName}/${source}?interval=${interval}`
}
