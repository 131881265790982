import { transfer } from '@src/domain/api/x10/trading/account/transfer'
import {
  getWithdrawalFee,
  getWithdrawalRemainingLimit,
  getWithdrawalTarget,
  withdrawal,
} from '@src/domain/api/x10/trading/account/withdrawal'

import {
  getInfo,
  getWalletEligibility,
  getWsToken,
  login,
  logout,
  refresh,
  register,
} from './auth/auth'
import { getReferralsConfig } from './info/referrals'
import { getSettings } from './info/settings'
import { getCurrentUser, getTopUsers } from './leaderboard/leaderboard'
import { accountUpdates } from './stream/account-updates'
import { candles } from './stream/candles'
import { orderBooks } from './stream/order-books'
import { publicTrades } from './stream/public-trades'
import { getAccountInfo } from './trading/account/account-info'
import { getAccounts } from './trading/account/accounts'
import { createApiKey } from './trading/account/api-key'
import { getAssetOperations } from './trading/account/asset-operations'
import { getBalance } from './trading/account/balance'
import { claimFunds, getClaimById, getNextClaim } from './trading/account/claim-funds'
import { getClientInfo } from './trading/account/client-info'
import { getFees } from './trading/account/fees'
import { getFundingHistory } from './trading/account/funding'
import { getLeverage, updateLeverage } from './trading/account/leverage'
import { getOrders } from './trading/account/orders'
import { getOrdersHistory } from './trading/account/orders-history'
import { getPositions } from './trading/account/positions'
import { getRealisedPnl } from './trading/account/realised-pnl'
import { getReferrals } from './trading/account/referrals'
import { createSubAccount } from './trading/account/subaccount'
import { getTrades } from './trading/account/trades'
import { getWithdrawals } from './trading/account/withdrawals'
import { getAssets } from './trading/markets-info/assets'
import { getFundingRates } from './trading/markets-info/funding-rates'
import {
  getMarkets,
  getMarketsStats,
  getMarketStats,
} from './trading/markets-info/markets'
import { getMarketOpenInterests } from './trading/markets-info/open-interests'
import {
  cancelOrder,
  massCancelOrder,
  placeOrder,
} from './trading/order-management/orders'

/**
 * https://github.com/x10xchange/api-specs
 */
export const API = {
  info: {
    getSettings,
    getReferralsConfig,
  },
  trading: {
    marketsInfo: {
      getAssets,

      getMarkets,
      getMarketStats,
      getMarketsStats,

      getFundingRates,
      getMarketOpenInterests,
    },
    account: {
      createSubAccount,
      createApiKey,
      getAccountInfo,
      getAccounts,
      getReferrals,

      getBalance,
      getFees,
      getFundingHistory,
      getOrders,
      getOrdersHistory,
      getPositions,
      getRealisedPnl,
      getTrades,
      getAssetOperations,

      getLeverage,
      updateLeverage,

      claimFunds,
      getClaimById,
      getNextClaim,

      transfer,
      withdrawal,
      getWithdrawals,
      getWithdrawalFee,
      getWithdrawalRemainingLimit,
      getWithdrawalTarget,
      getClientInfo,
    },
    orderManagement: {
      placeOrder,
      cancelOrder,
      massCancelOrder,
    },
  },
  stream: {
    orderBooks,
    publicTrades,
    candles,
    accountUpdates,
  },
  auth: {
    login,
    logout,
    register,
    getInfo,
    refresh,
    getWalletEligibility,
    getWsToken,
  },
  leaderboard: {
    getCurrentUser,
    getTopUsers,
  },
}
