import * as z from 'zod'

import { addRestEnvelope } from '@src/domain/api/utils/add-rest-envelope'
import { zodLong } from '@src/domain/api/utils/zod'

const TransferSchema = z.object({
  id: zodLong(),
})

export const TransferEnvelopedSchema = addRestEnvelope(TransferSchema)

export type Transfer = z.infer<typeof TransferSchema>
