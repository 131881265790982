import * as z from 'zod'

import { addRestEnvelope } from '@src/domain/api/utils/add-rest-envelope'
import { zodDecimal, zodLong } from '@src/domain/api/utils/zod'
import {
  MarketNameSchema,
  PositionSideSchema,
  TradeTypeSchema,
} from '@src/domain/api/x10/common'

const userRealisedPnlEntrySchema = z.object({
  id: zodLong(),
  accountId: zodLong(),
  market: MarketNameSchema,
  side: PositionSideSchema,
  size: zodDecimal(),
  maxPositionSize: zodDecimal(),
  leverage: zodDecimal(),
  openPrice: zodDecimal(),
  exitPrice: zodDecimal().optional(),
  realisedPnl: zodDecimal(),
  createdTime: z.coerce.date(),
  exitType: TradeTypeSchema.optional(),
  closedTime: z.coerce.date().optional(),
  realisedPnlBreakdown: z.object({
    tradePnl: zodDecimal(),
    fundingFees: zodDecimal(),
    openFees: zodDecimal(),
    closeFees: zodDecimal(),
  }),
})

export const getRealisedPnlSchema = addRestEnvelope(z.array(userRealisedPnlEntrySchema))

export type UserRealisedPnl = z.infer<typeof getRealisedPnlSchema>
export type UserRealisedPnlEntry = z.infer<typeof userRealisedPnlEntrySchema>
