import { axiosClient, type MarketName } from '@src/domain/api/x10/common'

import {
  EnvelopedMarketsSchema,
  EnvelopedMarketsStatsSchema,
  EnvelopedMarketStatsSchema,
} from './markets.schema'

export const getMarkets = async ({
  marketsNames,
}: { marketsNames?: MarketName[] } = {}) => {
  const { data } = await axiosClient.get<unknown>('/api/v1/info/markets', {
    params: {
      market: marketsNames,
    },
  })

  return EnvelopedMarketsSchema.parse(data)
}

export const getMarketStats = async (marketName: MarketName) => {
  const { data } = await axiosClient.get(`/api/v1/info/markets/${marketName}/stats`)

  return EnvelopedMarketStatsSchema.parse(data)
}

export const getMarketsStats = async ({
  marketsNames,
}: { marketsNames?: MarketName[] } = {}) => {
  const { data } = await axiosClient.get(`/api/v1/info/markets/stats`, {
    params: {
      market: marketsNames,
    },
  })

  return EnvelopedMarketsStatsSchema.parse(data)
}
