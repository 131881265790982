import { match } from 'ts-pattern'
import { create } from 'zustand'

import { invariant, type Decimal } from '@x10/lib-core/utils'

import type { TpSlEntrySide } from '@src/domain/core/types/common'
import type {
  OrderPriceType,
  OrderTpSlType,
  OrderTriggerDirection,
  OrderTriggerPriceType,
} from '@src/domain/starkex/stark-perpetual-order'
import type { OrderTriggerState } from '@src/domain/trade/types/common'
import { type FieldName } from '@src/domain/trade/validation/order-form/types'
import type { FormValidationErrors } from '@src/domain/trade/validation/types'

export type OrderType = 'LIMIT' | 'MARKET' | 'CONDITIONAL'

export type NewOrderTimeInForce =
  | 'good-till-cancel'
  | 'good-till-date'
  | 'immediate-or-cancel'
  | 'fill-or-kill'

type NewOrderStoreState = {
  orderType: OrderType
  priceType: OrderPriceType
  reduceOnly?: boolean
  postOnly?: boolean
  timeInForce: NewOrderTimeInForce
  expiryTime?: Date

  buy: {
    alerts: string[] | undefined
    errors: FormValidationErrors<FieldName> | undefined
    amountOfSynthetic: Decimal | null
    price: Decimal | null
  }
  sell: {
    alerts: string[] | undefined
    errors: FormValidationErrors<FieldName> | undefined
    amountOfSynthetic: Decimal | null
    price: Decimal | null
  }

  conditional: {
    triggerPrice: Decimal | null
    triggerPriceType: OrderTriggerPriceType
    triggerDirection: OrderTriggerDirection
  }

  tpsl: {
    /**
     * Side of the TP/SL entry entity (position or order).
     * This field is used for risk alert check.
     */
    tpSlEntrySide?: TpSlEntrySide
    tpSlType?: OrderTpSlType
    /**
     * Take Profit Trigger
     * (`null` if user disabled it explicitly)
     */
    takeProfit?: OrderTriggerState | null
    /**
     * Stop Loss Trigger
     * (`null` if user disabled it explicitly)
     */
    stopLoss?: OrderTriggerState | null
  }

  ui: {
    orderPlacedSeq: number
    userPriceSpecified: boolean
    amountOfSyntheticPct: number | undefined
  }
}

const INITIAL_STATE: NewOrderStoreState = {
  orderType: 'LIMIT',
  priceType: 'LIMIT',
  reduceOnly: false,
  postOnly: false,
  timeInForce: 'good-till-cancel',
  expiryTime: undefined,

  buy: {
    alerts: undefined,
    errors: { '@internal': 'Not initialized' },
    amountOfSynthetic: null,
    price: null,
  },
  sell: {
    alerts: undefined,
    errors: { '@internal': 'Not initialized' },
    amountOfSynthetic: null,
    price: null,
  },

  conditional: {
    triggerPrice: null,
    triggerPriceType: 'LAST',
    triggerDirection: 'UP',
  },

  tpsl: {
    tpSlEntrySide: undefined,
    tpSlType: undefined,
    takeProfit: undefined,
    stopLoss: undefined,
  },

  ui: {
    orderPlacedSeq: 0,
    userPriceSpecified: false,
    amountOfSyntheticPct: undefined,
  },
}

type StoreTransitionInternal = { type: 'order-type-changed'; orderType: OrderType }
type StoreTransition = { type: 'market-changed' } | { type: 'order-placed' }

const transitionStoreState = (
  prevState: Readonly<NewOrderStoreState>,
  transition: StoreTransitionInternal | StoreTransition,
) => {
  const newState: NewOrderStoreState = {
    ...INITIAL_STATE,
  }

  match(transition)
    // Preserve price and qty (including pct) except for `MARKET`
    .with({ type: 'order-type-changed' }, ({ orderType }) => {
      newState.orderType = orderType

      const userPriceSpecified =
        orderType !== 'MARKET' ? prevState.ui.userPriceSpecified : false
      const [buyPrice, sellPrice] = userPriceSpecified
        ? [prevState.buy.price, prevState.sell.price]
        : [newState.buy.price, newState.sell.price]

      newState.buy = {
        ...newState.buy,
        price: buyPrice,
        amountOfSynthetic: prevState.buy.amountOfSynthetic,
      }
      newState.sell = {
        ...newState.sell,
        price: sellPrice,
        amountOfSynthetic: prevState.sell.amountOfSynthetic,
      }
      newState.ui = {
        ...newState.ui,
        amountOfSyntheticPct: prevState.ui.amountOfSyntheticPct,
        userPriceSpecified,
      }
    })
    // Reset all
    .with({ type: 'market-changed' }, () => {
      newState.orderType = prevState.orderType
    })
    // Preserve qty (including pct) except for `MARKET`
    .with({ type: 'order-placed' }, () => {
      newState.orderType = prevState.orderType

      if (prevState.orderType !== 'MARKET') {
        newState.buy = {
          ...newState.buy,
          price: null,
          amountOfSynthetic: prevState.buy.amountOfSynthetic,
        }
        newState.sell = {
          ...newState.sell,
          price: null,
          amountOfSynthetic: prevState.sell.amountOfSynthetic,
        }
        newState.ui = {
          ...newState.ui,
          userPriceSpecified: true,
          amountOfSyntheticPct: prevState.ui.amountOfSyntheticPct,
        }
      }
    })
    .exhaustive()

  if (newState.orderType === 'MARKET') {
    newState.priceType = 'MARKET'
    newState.timeInForce = 'immediate-or-cancel'
  }

  newState.ui.orderPlacedSeq = prevState.ui.orderPlacedSeq + 1

  return newState
}

export const useNewOrderStore = create<Readonly<NewOrderStoreState>>(() => ({
  ...INITIAL_STATE,
}))

const setState = useNewOrderStore.setState

export const NewOrderStoreActions = {
  setAlertsAndErrors: (
    buyAlerts: string[] | undefined,
    buyErrors: FormValidationErrors<FieldName> | undefined,
    sellAlerts: string[] | undefined,
    sellErrors: FormValidationErrors<FieldName> | undefined,
  ) => {
    setState((state) => ({
      buy: {
        ...state.buy,
        alerts: buyAlerts,
        errors: buyErrors,
      },
      sell: {
        ...state.sell,
        alerts: sellAlerts,
        errors: sellErrors,
      },
    }))
  },

  setOrderType(value: OrderType) {
    setState((state) =>
      transitionStoreState(state, { type: 'order-type-changed', orderType: value }),
    )
  },

  setAmountOfSynthetic(
    buyAmountOfSynthetic: Decimal | null,
    sellAmountOfSynthetic: Decimal | null,
    amountOfSyntheticPct: number | undefined,
  ) {
    setState((state) => ({
      buy: {
        ...state.buy,
        amountOfSynthetic: buyAmountOfSynthetic,
      },
      sell: {
        ...state.sell,
        amountOfSynthetic: sellAmountOfSynthetic,
      },
      ui: {
        ...state.ui,
        amountOfSyntheticPct,
      },
    }))
  },

  setPrice(
    buyPrice: Decimal | null,
    sellPrice: Decimal | null,
    userPriceSpecified: boolean,
  ) {
    setState((state) => {
      invariant(
        !userPriceSpecified || state.orderType !== 'MARKET',
        '`userPriceSpecified` can not be set for `MARKET` orders',
      )
      invariant(
        !userPriceSpecified || !buyPrice || !sellPrice || buyPrice.eq(sellPrice),
        '`buy` and `sell` prices must be equal',
      )

      return {
        buy: {
          ...state.buy,
          price: buyPrice,
        },
        sell: {
          ...state.sell,
          price: sellPrice,
        },
        ui: {
          ...state.ui,
          userPriceSpecified,
        },
      }
    })
  },

  setPriceType(value: OrderPriceType) {
    setState((state) => {
      invariant(
        state.orderType !== 'MARKET',
        '`priceType` can not be set for `MARKET` orders',
      )

      const nextState: Partial<NewOrderStoreState> = {
        priceType: value,
      }

      nextState.timeInForce = value === 'MARKET' ? 'fill-or-kill' : 'good-till-cancel'
      nextState.expiryTime = undefined

      return nextState
    })
  },

  setReduceOnly(value: boolean | undefined) {
    setState({ reduceOnly: value })
  },

  setPostOnly(value: boolean | undefined) {
    setState((state) => {
      invariant(
        state.orderType !== 'MARKET',
        '`postOnly` can not be set for `MARKET` orders',
      )

      return { postOnly: value, timeInForce: 'good-till-cancel', expiryTime: undefined }
    })
  },

  setTimeInForce(timeInForce: NewOrderTimeInForce, expiryTime: Date | undefined) {
    setState((state) => {
      invariant(
        state.orderType !== 'MARKET',
        '`timeInForce` can not be set for `MARKET` orders',
      )

      if (expiryTime) {
        invariant(
          timeInForce === 'good-till-date',
          '`expiryTime` can only be set when `timeInForce` is `good-till-date`',
        )
      }

      const nextState: Partial<NewOrderStoreState> = {
        timeInForce,
        expiryTime,
      }

      if (timeInForce !== 'good-till-cancel') {
        nextState.postOnly = false
      }

      return nextState
    })
  },

  setTriggerPrice(value: Decimal | null) {
    setState((state) => {
      invariant(
        state.orderType === 'CONDITIONAL',
        '`triggerPrice` can only be set for `CONDITIONAL` orders',
      )

      return {
        conditional: {
          ...state.conditional,
          triggerPrice: value,
        },
      }
    })
  },

  setTriggerPriceType(value: OrderTriggerPriceType) {
    setState((state) => {
      invariant(
        state.orderType === 'CONDITIONAL',
        '`triggerPriceType` can only be set for `CONDITIONAL` orders',
      )

      return {
        conditional: {
          ...state.conditional,
          triggerPriceType: value,
        },
      }
    })
  },

  setTriggerDirection(value: OrderTriggerDirection) {
    setState((state) => {
      invariant(
        state.orderType === 'CONDITIONAL',
        '`triggerDirection` can only be set for `CONDITIONAL` orders',
      )

      return {
        conditional: {
          ...state.conditional,
          triggerDirection: value,
        },
      }
    })
  },

  setTpSlEntrySide(value: TpSlEntrySide | undefined) {
    setState((state) => {
      return {
        tpsl: {
          ...state.tpsl,
          tpSlEntrySide: value,
        },
      }
    })
  },

  setTpSlType(value: OrderTpSlType | undefined) {
    setState((state) => {
      return {
        tpsl: {
          ...state.tpsl,
          tpSlType: value,
        },
      }
    })
  },

  setTakeProfit(value: OrderTriggerState | undefined | null) {
    setState((state) => {
      return {
        tpsl: {
          ...state.tpsl,
          takeProfit: value,
        },
      }
    })
  },

  setStopLoss(value: OrderTriggerState | undefined | null) {
    setState((state) => {
      return {
        tpsl: {
          ...state.tpsl,
          stopLoss: value,
        },
      }
    })
  },

  reset(transition: StoreTransition) {
    setState((state) => transitionStoreState(state, transition))
  },
} as const
