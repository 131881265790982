import { useCallback } from 'react'

import { useFeaturesStore, type FeatureName } from '@src/domain/core/store/features'

type UseIsFeatureEnabledResult = (name: FeatureName) => boolean

export const useIsFeatureEnabled = (): UseIsFeatureEnabledResult => {
  const features = useFeaturesStore((state) => state.features)

  return useCallback((name: FeatureName) => features.includes(name), [features])
}
