import { axiosClient, type MarketName } from '../../common'
import { EnvelopedMarketOpenInterestSchema } from './open-interests.schema'

export type OpenInterestsInterval = 'PT1H' | 'P1D'

export const getMarketOpenInterests = async (
  market: MarketName,
  startTime: number,
  endTime: number,
  interval: OpenInterestsInterval,
  limit?: number,
) => {
  const { data } = await axiosClient.get<unknown>(
    `/api/v1/info/${market}/open-interests`,
    {
      params: {
        market,
        startTime,
        endTime,
        interval,
        limit,
      },
    },
  )

  return EnvelopedMarketOpenInterestSchema.parse(data)
}
