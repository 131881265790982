import { axiosClient, withActiveAccount } from '@src/domain/api/x10/common'

import { EnvelopedFeesSchema } from './fees.schema'

export const getFees = async (
  args: { marketsNames?: string[] } = {},
  accountId?: string,
) => {
  const { data } = await axiosClient.get<unknown>('/api/v1/user/fees', {
    params: {
      market: args.marketsNames,
    },
    headers: withActiveAccount(accountId),
  })

  return EnvelopedFeesSchema.parse(data)
}
