const tokens = {
  "animations.backdrop-in": {
    "value": "fade-in 250ms var(--easings-default)",
    "variable": "var(--animations-backdrop-in)"
  },
  "animations.backdrop-out": {
    "value": "fade-out 200ms var(--easings-default)",
    "variable": "var(--animations-backdrop-out)"
  },
  "animations.dialog-in": {
    "value": "slide-in 400ms var(--easings-emphasized-in)",
    "variable": "var(--animations-dialog-in)"
  },
  "animations.dialog-out": {
    "value": "slide-out 200ms var(--easings-emphasized-out)",
    "variable": "var(--animations-dialog-out)"
  },
  "colors.dark.black": {
    "value": "#000000",
    "variable": "var(--colors-dark-black)"
  },
  "colors.dark.black-25": {
    "value": "rgba(0, 0, 0, 0.25)",
    "variable": "var(--colors-dark-black-25)"
  },
  "colors.dark.black-80": {
    "value": "rgba(0, 0, 0, 0.80)",
    "variable": "var(--colors-dark-black-80)"
  },
  "colors.dark.grey": {
    "value": "#121212",
    "variable": "var(--colors-dark-grey)"
  },
  "colors.dark.grey-90": {
    "value": "#1e1e1e",
    "variable": "var(--colors-dark-grey-90)"
  },
  "colors.dark.grey-80": {
    "value": "rgba(18, 18, 18, 0.8)",
    "variable": "var(--colors-dark-grey-80)"
  },
  "colors.dark.white": {
    "value": "#ffffff",
    "variable": "var(--colors-dark-white)"
  },
  "colors.dark.white-50": {
    "value": "rgba(255, 255, 255, 0.5)",
    "variable": "var(--colors-dark-white-50)"
  },
  "colors.dark.white-20": {
    "value": "rgba(255, 255, 255, 0.2)",
    "variable": "var(--colors-dark-white-20)"
  },
  "colors.dark.white-10": {
    "value": "rgba(255, 255, 255, 0.1)",
    "variable": "var(--colors-dark-white-10)"
  },
  "colors.dark.white-5": {
    "value": "rgba(255, 255, 255, 0.05)",
    "variable": "var(--colors-dark-white-5)"
  },
  "colors.dark.green": {
    "value": "#0aae4e",
    "variable": "var(--colors-dark-green)"
  },
  "colors.dark.green-90": {
    "value": "rgba(10, 174, 78, 0.9)",
    "variable": "var(--colors-dark-green-90)"
  },
  "colors.dark.green-50": {
    "value": "rgba(10, 174, 78, 0.5)",
    "variable": "var(--colors-dark-green-50)"
  },
  "colors.dark.green-10": {
    "value": "rgba(10, 174, 78, 0.1)",
    "variable": "var(--colors-dark-green-10)"
  },
  "colors.dark.red": {
    "value": "#f8434d",
    "variable": "var(--colors-dark-red)"
  },
  "colors.dark.red-50": {
    "value": "rgba(248, 67, 77, 0.5)",
    "variable": "var(--colors-dark-red-50)"
  },
  "colors.dark.red-20": {
    "value": "rgba(248, 67, 77, 0.2)",
    "variable": "var(--colors-dark-red-20)"
  },
  "colors.dark.red-10": {
    "value": "rgba(248, 67, 77, 0.1)",
    "variable": "var(--colors-dark-red-10)"
  },
  "colors.dark.red-5": {
    "value": "rgba(248, 67, 77, 0.05)",
    "variable": "var(--colors-dark-red-5)"
  },
  "colors.dark.orange": {
    "value": "#fc9338",
    "variable": "var(--colors-dark-orange)"
  },
  "colors.dark.yellow": {
    "value": "#ffcc00",
    "variable": "var(--colors-dark-yellow)"
  },
  "colors.dark.blue": {
    "value": "#1144c8",
    "variable": "var(--colors-dark-blue)"
  },
  "colors.light.black": {
    "value": "#000000",
    "variable": "var(--colors-light-black)"
  },
  "colors.light.black-80": {
    "value": "rgba(0,0,0,0.8)",
    "variable": "var(--colors-light-black-80)"
  },
  "colors.light.black-50": {
    "value": "rgba(0, 0, 0, 0.5)",
    "variable": "var(--colors-light-black-50)"
  },
  "colors.light.black-25": {
    "value": "rgba(0, 0, 0, 0.25)",
    "variable": "var(--colors-light-black-25)"
  },
  "colors.light.black-20": {
    "value": "rgba(0, 0, 0, 0.2)",
    "variable": "var(--colors-light-black-20)"
  },
  "colors.light.black-10": {
    "value": "rgba(0, 0, 0, 0.1)",
    "variable": "var(--colors-light-black-10)"
  },
  "colors.light.black-5": {
    "value": "rgba(0, 0, 0, 0.05)",
    "variable": "var(--colors-light-black-5)"
  },
  "colors.light.grey": {
    "value": "#ededed",
    "variable": "var(--colors-light-grey)"
  },
  "colors.light.grey-90": {
    "value": "#202020",
    "variable": "var(--colors-light-grey-90)"
  },
  "colors.light.white": {
    "value": "#ffffff",
    "variable": "var(--colors-light-white)"
  },
  "colors.light.white-80": {
    "value": "rgba(255, 255, 255, 0.80)",
    "variable": "var(--colors-light-white-80)"
  },
  "colors.light.white-50": {
    "value": "rgba(255, 255, 255, 0.5)",
    "variable": "var(--colors-light-white-50)"
  },
  "colors.light.white-20": {
    "value": "rgba(255, 255, 255, 0.2)",
    "variable": "var(--colors-light-white-20)"
  },
  "colors.light.white-10": {
    "value": "rgba(255, 255, 255, 0.1)",
    "variable": "var(--colors-light-white-10)"
  },
  "colors.light.white-5": {
    "value": "rgba(255, 255, 255, 0.05)",
    "variable": "var(--colors-light-white-5)"
  },
  "colors.light.green": {
    "value": "#0aa84b",
    "variable": "var(--colors-light-green)"
  },
  "colors.light.green-90": {
    "value": "rgba(10, 174, 78, 0.9)",
    "variable": "var(--colors-light-green-90)"
  },
  "colors.light.green-50": {
    "value": "rgba(10, 174, 78, 0.5)",
    "variable": "var(--colors-light-green-50)"
  },
  "colors.light.green-10": {
    "value": "rgba(10, 168, 75, 0.1)",
    "variable": "var(--colors-light-green-10)"
  },
  "colors.light.red": {
    "value": "#f2414b",
    "variable": "var(--colors-light-red)"
  },
  "colors.light.red-50": {
    "value": "rgba(248, 67, 77, 0.5)",
    "variable": "var(--colors-light-red-50)"
  },
  "colors.light.red-20": {
    "value": "rgba(248, 67, 77, 0.2)",
    "variable": "var(--colors-light-red-20)"
  },
  "colors.light.red-10": {
    "value": "rgba(242, 65, 75, 0.1)",
    "variable": "var(--colors-light-red-10)"
  },
  "colors.light.red-5": {
    "value": "rgba(248, 67, 77, 0.05)",
    "variable": "var(--colors-light-red-5)"
  },
  "colors.light.orange": {
    "value": "#f79037",
    "variable": "var(--colors-light-orange)"
  },
  "colors.light.yellow": {
    "value": "#ffcc00",
    "variable": "var(--colors-light-yellow)"
  },
  "colors.light.blue": {
    "value": "#1144c8",
    "variable": "var(--colors-light-blue)"
  },
  "durations.normal": {
    "value": "200ms",
    "variable": "var(--durations-normal)"
  },
  "easings.default": {
    "value": "cubic-bezier(0.4, 0, 0.2, 1)",
    "variable": "var(--easings-default)"
  },
  "easings.emphasized-in": {
    "value": "cubic-bezier(0.05, 0.7, 0.1, 1.0)",
    "variable": "var(--easings-emphasized-in)"
  },
  "easings.emphasized-out": {
    "value": "cubic-bezier(0.3, 0.0, 0.8, 0.15)",
    "variable": "var(--easings-emphasized-out)"
  },
  "fontSizes.fs-10": {
    "value": "0.625rem",
    "variable": "var(--font-sizes-fs-10)"
  },
  "fontSizes.fs-12": {
    "value": "0.75rem",
    "variable": "var(--font-sizes-fs-12)"
  },
  "fontSizes.fs-14": {
    "value": "0.875rem",
    "variable": "var(--font-sizes-fs-14)"
  },
  "fontSizes.fs-16": {
    "value": "1rem",
    "variable": "var(--font-sizes-fs-16)"
  },
  "fontSizes.fs-24": {
    "value": "1.5rem",
    "variable": "var(--font-sizes-fs-24)"
  },
  "fontSizes.fs-32": {
    "value": "2rem",
    "variable": "var(--font-sizes-fs-32)"
  },
  "fontSizes.fs-56": {
    "value": "3.5rem",
    "variable": "var(--font-sizes-fs-56)"
  },
  "fontWeights.fw-400": {
    "value": 400,
    "variable": "var(--font-weights-fw-400)"
  },
  "fontWeights.fw-500": {
    "value": 500,
    "variable": "var(--font-weights-fw-500)"
  },
  "fontWeights.fw-600": {
    "value": 600,
    "variable": "var(--font-weights-fw-600)"
  },
  "radii.r-2": {
    "value": "0.125rem",
    "variable": "var(--radii-r-2)"
  },
  "radii.r-4": {
    "value": "0.25rem",
    "variable": "var(--radii-r-4)"
  },
  "radii.r-8": {
    "value": "0.5rem",
    "variable": "var(--radii-r-8)"
  },
  "radii.r-12": {
    "value": "0.75rem",
    "variable": "var(--radii-r-12)"
  },
  "radii.r-16": {
    "value": "1rem",
    "variable": "var(--radii-r-16)"
  },
  "radii.r-24": {
    "value": "1.5rem",
    "variable": "var(--radii-r-24)"
  },
  "radii.r-full": {
    "value": "9999px",
    "variable": "var(--radii-r-full)"
  },
  "spacing.s-2": {
    "value": "0.125rem",
    "variable": "var(--spacing-s-2)"
  },
  "spacing.s-4": {
    "value": "0.25rem",
    "variable": "var(--spacing-s-4)"
  },
  "spacing.s-8": {
    "value": "0.5rem",
    "variable": "var(--spacing-s-8)"
  },
  "spacing.s-12": {
    "value": "0.75rem",
    "variable": "var(--spacing-s-12)"
  },
  "spacing.s-14": {
    "value": "0.875rem",
    "variable": "var(--spacing-s-14)"
  },
  "spacing.s-16": {
    "value": "1rem",
    "variable": "var(--spacing-s-16)"
  },
  "spacing.s-24": {
    "value": "1.5rem",
    "variable": "var(--spacing-s-24)"
  },
  "spacing.s-32": {
    "value": "2rem",
    "variable": "var(--spacing-s-32)"
  },
  "spacing.s-48": {
    "value": "3rem",
    "variable": "var(--spacing-s-48)"
  },
  "spacing.s-56": {
    "value": "3.5rem",
    "variable": "var(--spacing-s-56)"
  },
  "spacing.s-64": {
    "value": "4rem",
    "variable": "var(--spacing-s-64)"
  },
  "spacing.s-86": {
    "value": "5.375rem",
    "variable": "var(--spacing-s-86)"
  },
  "spacing.s-128": {
    "value": "8rem",
    "variable": "var(--spacing-s-128)"
  },
  "zIndex.z-1": {
    "value": 1,
    "variable": "var(--z-index-z-1)"
  },
  "zIndex.z-2": {
    "value": 2,
    "variable": "var(--z-index-z-2)"
  },
  "zIndex.z-3": {
    "value": 3,
    "variable": "var(--z-index-z-3)"
  },
  "breakpoints.sm": {
    "value": "20rem",
    "variable": "var(--breakpoints-sm)"
  },
  "breakpoints.md": {
    "value": "46.5rem",
    "variable": "var(--breakpoints-md)"
  },
  "breakpoints.lg": {
    "value": "85.375rem",
    "variable": "var(--breakpoints-lg)"
  },
  "breakpoints.xl": {
    "value": "120rem",
    "variable": "var(--breakpoints-xl)"
  },
  "breakpoints.2xl": {
    "value": "160rem",
    "variable": "var(--breakpoints-2xl)"
  },
  "sizes.breakpoint-sm": {
    "value": "20rem",
    "variable": "var(--sizes-breakpoint-sm)"
  },
  "sizes.breakpoint-md": {
    "value": "46.5rem",
    "variable": "var(--sizes-breakpoint-md)"
  },
  "sizes.breakpoint-lg": {
    "value": "85.375rem",
    "variable": "var(--sizes-breakpoint-lg)"
  },
  "sizes.breakpoint-xl": {
    "value": "120rem",
    "variable": "var(--sizes-breakpoint-xl)"
  },
  "sizes.breakpoint-2xl": {
    "value": "160rem",
    "variable": "var(--sizes-breakpoint-2xl)"
  },
  "zIndex.token.app": {
    "value": "var(--z-index-z-1)",
    "variable": "var(--z-index-token-app)"
  },
  "zIndex.token.fullscreen": {
    "value": "var(--z-index-z-2)",
    "variable": "var(--z-index-token-fullscreen)"
  },
  "zIndex.token.floating": {
    "value": "var(--z-index-z-3)",
    "variable": "var(--z-index-token-floating)"
  },
  "spacing.-s-2": {
    "value": "calc(var(--spacing-s-2) * -1)",
    "variable": "var(--spacing-s-2)"
  },
  "spacing.-s-4": {
    "value": "calc(var(--spacing-s-4) * -1)",
    "variable": "var(--spacing-s-4)"
  },
  "spacing.-s-8": {
    "value": "calc(var(--spacing-s-8) * -1)",
    "variable": "var(--spacing-s-8)"
  },
  "spacing.-s-12": {
    "value": "calc(var(--spacing-s-12) * -1)",
    "variable": "var(--spacing-s-12)"
  },
  "spacing.-s-14": {
    "value": "calc(var(--spacing-s-14) * -1)",
    "variable": "var(--spacing-s-14)"
  },
  "spacing.-s-16": {
    "value": "calc(var(--spacing-s-16) * -1)",
    "variable": "var(--spacing-s-16)"
  },
  "spacing.-s-24": {
    "value": "calc(var(--spacing-s-24) * -1)",
    "variable": "var(--spacing-s-24)"
  },
  "spacing.-s-32": {
    "value": "calc(var(--spacing-s-32) * -1)",
    "variable": "var(--spacing-s-32)"
  },
  "spacing.-s-48": {
    "value": "calc(var(--spacing-s-48) * -1)",
    "variable": "var(--spacing-s-48)"
  },
  "spacing.-s-56": {
    "value": "calc(var(--spacing-s-56) * -1)",
    "variable": "var(--spacing-s-56)"
  },
  "spacing.-s-64": {
    "value": "calc(var(--spacing-s-64) * -1)",
    "variable": "var(--spacing-s-64)"
  },
  "spacing.-s-86": {
    "value": "calc(var(--spacing-s-86) * -1)",
    "variable": "var(--spacing-s-86)"
  },
  "spacing.-s-128": {
    "value": "calc(var(--spacing-s-128) * -1)",
    "variable": "var(--spacing-s-128)"
  },
  "colors.token.black": {
    "value": "var(--colors-token-black)",
    "variable": "var(--colors-token-black)"
  },
  "colors.token.white": {
    "value": "var(--colors-token-white)",
    "variable": "var(--colors-token-white)"
  },
  "colors.token.green": {
    "value": "var(--colors-token-green)",
    "variable": "var(--colors-token-green)"
  },
  "colors.token.red": {
    "value": "var(--colors-token-red)",
    "variable": "var(--colors-token-red)"
  },
  "colors.token.orange": {
    "value": "var(--colors-token-orange)",
    "variable": "var(--colors-token-orange)"
  },
  "colors.token.yellow": {
    "value": "var(--colors-token-yellow)",
    "variable": "var(--colors-token-yellow)"
  },
  "colors.token.blue": {
    "value": "var(--colors-token-blue)",
    "variable": "var(--colors-token-blue)"
  },
  "colors.token.green-10": {
    "value": "var(--colors-token-green-10)",
    "variable": "var(--colors-token-green-10)"
  },
  "colors.token.green-10-opaque": {
    "value": "var(--colors-token-green-10-opaque)",
    "variable": "var(--colors-token-green-10-opaque)"
  },
  "colors.token.green-50": {
    "value": "var(--colors-token-green-50)",
    "variable": "var(--colors-token-green-50)"
  },
  "colors.token.green-90": {
    "value": "var(--colors-token-green-90)",
    "variable": "var(--colors-token-green-90)"
  },
  "colors.token.red-5": {
    "value": "var(--colors-token-red-5)",
    "variable": "var(--colors-token-red-5)"
  },
  "colors.token.red-10": {
    "value": "var(--colors-token-red-10)",
    "variable": "var(--colors-token-red-10)"
  },
  "colors.token.red-10-opaque": {
    "value": "var(--colors-token-red-10-opaque)",
    "variable": "var(--colors-token-red-10-opaque)"
  },
  "colors.token.red-20": {
    "value": "var(--colors-token-red-20)",
    "variable": "var(--colors-token-red-20)"
  },
  "colors.token.red-50": {
    "value": "var(--colors-token-red-50)",
    "variable": "var(--colors-token-red-50)"
  },
  "colors.token.grey": {
    "value": "var(--colors-token-grey)",
    "variable": "var(--colors-token-grey)"
  },
  "colors.token.grey-90": {
    "value": "var(--colors-token-grey-90)",
    "variable": "var(--colors-token-grey-90)"
  },
  "colors.token.white-50": {
    "value": "var(--colors-token-white-50)",
    "variable": "var(--colors-token-white-50)"
  },
  "colors.token.white-20": {
    "value": "var(--colors-token-white-20)",
    "variable": "var(--colors-token-white-20)"
  },
  "colors.token.white-10": {
    "value": "var(--colors-token-white-10)",
    "variable": "var(--colors-token-white-10)"
  },
  "colors.token.white-10-opaque": {
    "value": "var(--colors-token-white-10-opaque)",
    "variable": "var(--colors-token-white-10-opaque)"
  },
  "colors.token.white-5": {
    "value": "var(--colors-token-white-5)",
    "variable": "var(--colors-token-white-5)"
  },
  "colors.token.black-25": {
    "value": "var(--colors-token-black-25)",
    "variable": "var(--colors-token-black-25)"
  },
  "colors.token.black-80": {
    "value": "var(--colors-token-black-80)",
    "variable": "var(--colors-token-black-80)"
  },
  "colors.token.button.green.background": {
    "value": "var(--colors-token-button-green-background)",
    "variable": "var(--colors-token-button-green-background)"
  },
  "colors.token.button.green.hover": {
    "value": "var(--colors-token-button-green-hover)",
    "variable": "var(--colors-token-button-green-hover)"
  },
  "colors.token.button.green.active": {
    "value": "var(--colors-token-button-green-active)",
    "variable": "var(--colors-token-button-green-active)"
  },
  "colors.token.button.green.disabled": {
    "value": "var(--colors-token-button-green-disabled)",
    "variable": "var(--colors-token-button-green-disabled)"
  },
  "colors.token.button.red.background": {
    "value": "var(--colors-token-button-red-background)",
    "variable": "var(--colors-token-button-red-background)"
  },
  "colors.token.button.red.hover": {
    "value": "var(--colors-token-button-red-hover)",
    "variable": "var(--colors-token-button-red-hover)"
  },
  "colors.token.button.red.active": {
    "value": "var(--colors-token-button-red-active)",
    "variable": "var(--colors-token-button-red-active)"
  },
  "colors.token.button.red.disabled": {
    "value": "var(--colors-token-button-red-disabled)",
    "variable": "var(--colors-token-button-red-disabled)"
  },
  "colors.token.button.grey.background": {
    "value": "var(--colors-token-button-grey-background)",
    "variable": "var(--colors-token-button-grey-background)"
  },
  "colors.token.button.grey.hover": {
    "value": "var(--colors-token-button-grey-hover)",
    "variable": "var(--colors-token-button-grey-hover)"
  },
  "colors.token.button.grey.active": {
    "value": "var(--colors-token-button-grey-active)",
    "variable": "var(--colors-token-button-grey-active)"
  },
  "colors.token.button.grey.disabled": {
    "value": "var(--colors-token-button-grey-disabled)",
    "variable": "var(--colors-token-button-grey-disabled)"
  },
  "colors.colorPalette.black": {
    "value": "var(--colors-color-palette-black)",
    "variable": "var(--colors-color-palette-black)"
  },
  "colors.colorPalette.black-25": {
    "value": "var(--colors-color-palette-black-25)",
    "variable": "var(--colors-color-palette-black-25)"
  },
  "colors.colorPalette.black-80": {
    "value": "var(--colors-color-palette-black-80)",
    "variable": "var(--colors-color-palette-black-80)"
  },
  "colors.colorPalette.grey": {
    "value": "var(--colors-color-palette-grey)",
    "variable": "var(--colors-color-palette-grey)"
  },
  "colors.colorPalette.grey-90": {
    "value": "var(--colors-color-palette-grey-90)",
    "variable": "var(--colors-color-palette-grey-90)"
  },
  "colors.colorPalette.grey-80": {
    "value": "var(--colors-color-palette-grey-80)",
    "variable": "var(--colors-color-palette-grey-80)"
  },
  "colors.colorPalette.white": {
    "value": "var(--colors-color-palette-white)",
    "variable": "var(--colors-color-palette-white)"
  },
  "colors.colorPalette.white-50": {
    "value": "var(--colors-color-palette-white-50)",
    "variable": "var(--colors-color-palette-white-50)"
  },
  "colors.colorPalette.white-20": {
    "value": "var(--colors-color-palette-white-20)",
    "variable": "var(--colors-color-palette-white-20)"
  },
  "colors.colorPalette.white-10": {
    "value": "var(--colors-color-palette-white-10)",
    "variable": "var(--colors-color-palette-white-10)"
  },
  "colors.colorPalette.white-5": {
    "value": "var(--colors-color-palette-white-5)",
    "variable": "var(--colors-color-palette-white-5)"
  },
  "colors.colorPalette.green": {
    "value": "var(--colors-color-palette-green)",
    "variable": "var(--colors-color-palette-green)"
  },
  "colors.colorPalette.green-90": {
    "value": "var(--colors-color-palette-green-90)",
    "variable": "var(--colors-color-palette-green-90)"
  },
  "colors.colorPalette.green-50": {
    "value": "var(--colors-color-palette-green-50)",
    "variable": "var(--colors-color-palette-green-50)"
  },
  "colors.colorPalette.green-10": {
    "value": "var(--colors-color-palette-green-10)",
    "variable": "var(--colors-color-palette-green-10)"
  },
  "colors.colorPalette.red": {
    "value": "var(--colors-color-palette-red)",
    "variable": "var(--colors-color-palette-red)"
  },
  "colors.colorPalette.red-50": {
    "value": "var(--colors-color-palette-red-50)",
    "variable": "var(--colors-color-palette-red-50)"
  },
  "colors.colorPalette.red-20": {
    "value": "var(--colors-color-palette-red-20)",
    "variable": "var(--colors-color-palette-red-20)"
  },
  "colors.colorPalette.red-10": {
    "value": "var(--colors-color-palette-red-10)",
    "variable": "var(--colors-color-palette-red-10)"
  },
  "colors.colorPalette.red-5": {
    "value": "var(--colors-color-palette-red-5)",
    "variable": "var(--colors-color-palette-red-5)"
  },
  "colors.colorPalette.orange": {
    "value": "var(--colors-color-palette-orange)",
    "variable": "var(--colors-color-palette-orange)"
  },
  "colors.colorPalette.yellow": {
    "value": "var(--colors-color-palette-yellow)",
    "variable": "var(--colors-color-palette-yellow)"
  },
  "colors.colorPalette.blue": {
    "value": "var(--colors-color-palette-blue)",
    "variable": "var(--colors-color-palette-blue)"
  },
  "colors.colorPalette.black-50": {
    "value": "var(--colors-color-palette-black-50)",
    "variable": "var(--colors-color-palette-black-50)"
  },
  "colors.colorPalette.black-20": {
    "value": "var(--colors-color-palette-black-20)",
    "variable": "var(--colors-color-palette-black-20)"
  },
  "colors.colorPalette.black-10": {
    "value": "var(--colors-color-palette-black-10)",
    "variable": "var(--colors-color-palette-black-10)"
  },
  "colors.colorPalette.black-5": {
    "value": "var(--colors-color-palette-black-5)",
    "variable": "var(--colors-color-palette-black-5)"
  },
  "colors.colorPalette.white-80": {
    "value": "var(--colors-color-palette-white-80)",
    "variable": "var(--colors-color-palette-white-80)"
  },
  "colors.colorPalette.green-10-opaque": {
    "value": "var(--colors-color-palette-green-10-opaque)",
    "variable": "var(--colors-color-palette-green-10-opaque)"
  },
  "colors.colorPalette.red-10-opaque": {
    "value": "var(--colors-color-palette-red-10-opaque)",
    "variable": "var(--colors-color-palette-red-10-opaque)"
  },
  "colors.colorPalette.white-10-opaque": {
    "value": "var(--colors-color-palette-white-10-opaque)",
    "variable": "var(--colors-color-palette-white-10-opaque)"
  },
  "colors.colorPalette.button.green.background": {
    "value": "var(--colors-color-palette-button-green-background)",
    "variable": "var(--colors-color-palette-button-green-background)"
  },
  "colors.colorPalette.green.background": {
    "value": "var(--colors-color-palette-green-background)",
    "variable": "var(--colors-color-palette-green-background)"
  },
  "colors.colorPalette.background": {
    "value": "var(--colors-color-palette-background)",
    "variable": "var(--colors-color-palette-background)"
  },
  "colors.colorPalette.button.green.hover": {
    "value": "var(--colors-color-palette-button-green-hover)",
    "variable": "var(--colors-color-palette-button-green-hover)"
  },
  "colors.colorPalette.green.hover": {
    "value": "var(--colors-color-palette-green-hover)",
    "variable": "var(--colors-color-palette-green-hover)"
  },
  "colors.colorPalette.hover": {
    "value": "var(--colors-color-palette-hover)",
    "variable": "var(--colors-color-palette-hover)"
  },
  "colors.colorPalette.button.green.active": {
    "value": "var(--colors-color-palette-button-green-active)",
    "variable": "var(--colors-color-palette-button-green-active)"
  },
  "colors.colorPalette.green.active": {
    "value": "var(--colors-color-palette-green-active)",
    "variable": "var(--colors-color-palette-green-active)"
  },
  "colors.colorPalette.active": {
    "value": "var(--colors-color-palette-active)",
    "variable": "var(--colors-color-palette-active)"
  },
  "colors.colorPalette.button.green.disabled": {
    "value": "var(--colors-color-palette-button-green-disabled)",
    "variable": "var(--colors-color-palette-button-green-disabled)"
  },
  "colors.colorPalette.green.disabled": {
    "value": "var(--colors-color-palette-green-disabled)",
    "variable": "var(--colors-color-palette-green-disabled)"
  },
  "colors.colorPalette.disabled": {
    "value": "var(--colors-color-palette-disabled)",
    "variable": "var(--colors-color-palette-disabled)"
  },
  "colors.colorPalette.button.red.background": {
    "value": "var(--colors-color-palette-button-red-background)",
    "variable": "var(--colors-color-palette-button-red-background)"
  },
  "colors.colorPalette.red.background": {
    "value": "var(--colors-color-palette-red-background)",
    "variable": "var(--colors-color-palette-red-background)"
  },
  "colors.colorPalette.button.red.hover": {
    "value": "var(--colors-color-palette-button-red-hover)",
    "variable": "var(--colors-color-palette-button-red-hover)"
  },
  "colors.colorPalette.red.hover": {
    "value": "var(--colors-color-palette-red-hover)",
    "variable": "var(--colors-color-palette-red-hover)"
  },
  "colors.colorPalette.button.red.active": {
    "value": "var(--colors-color-palette-button-red-active)",
    "variable": "var(--colors-color-palette-button-red-active)"
  },
  "colors.colorPalette.red.active": {
    "value": "var(--colors-color-palette-red-active)",
    "variable": "var(--colors-color-palette-red-active)"
  },
  "colors.colorPalette.button.red.disabled": {
    "value": "var(--colors-color-palette-button-red-disabled)",
    "variable": "var(--colors-color-palette-button-red-disabled)"
  },
  "colors.colorPalette.red.disabled": {
    "value": "var(--colors-color-palette-red-disabled)",
    "variable": "var(--colors-color-palette-red-disabled)"
  },
  "colors.colorPalette.button.grey.background": {
    "value": "var(--colors-color-palette-button-grey-background)",
    "variable": "var(--colors-color-palette-button-grey-background)"
  },
  "colors.colorPalette.grey.background": {
    "value": "var(--colors-color-palette-grey-background)",
    "variable": "var(--colors-color-palette-grey-background)"
  },
  "colors.colorPalette.button.grey.hover": {
    "value": "var(--colors-color-palette-button-grey-hover)",
    "variable": "var(--colors-color-palette-button-grey-hover)"
  },
  "colors.colorPalette.grey.hover": {
    "value": "var(--colors-color-palette-grey-hover)",
    "variable": "var(--colors-color-palette-grey-hover)"
  },
  "colors.colorPalette.button.grey.active": {
    "value": "var(--colors-color-palette-button-grey-active)",
    "variable": "var(--colors-color-palette-button-grey-active)"
  },
  "colors.colorPalette.grey.active": {
    "value": "var(--colors-color-palette-grey-active)",
    "variable": "var(--colors-color-palette-grey-active)"
  },
  "colors.colorPalette.button.grey.disabled": {
    "value": "var(--colors-color-palette-button-grey-disabled)",
    "variable": "var(--colors-color-palette-button-grey-disabled)"
  },
  "colors.colorPalette.grey.disabled": {
    "value": "var(--colors-color-palette-grey-disabled)",
    "variable": "var(--colors-color-palette-grey-disabled)"
  }
}

export function token(path, fallback) {
  return tokens[path]?.value || fallback
}

function tokenVar(path, fallback) {
  return tokens[path]?.variable || fallback
}

token.var = tokenVar