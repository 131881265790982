import {
  axiosClient,
  withActiveAccount,
  type MarketName,
  type OrderSide,
  type OrderType,
} from '@src/domain/api/x10/common'

import { EnvelopedUserOrdersSchema } from './orders.schema'

export type OrdersArgs = {
  marketsNames?: MarketName[]
  orderType?: OrderType[]
  orderSide?: OrderSide[]
}

export const getOrders = async (args: OrdersArgs = {}, accountId?: string) => {
  const { data } = await axiosClient.get<unknown>('/api/v1/user/orders', {
    params: {
      market: args.marketsNames,
      type: args.orderType,
      side: args.orderSide,
    },
    headers: withActiveAccount(accountId),
  })

  return EnvelopedUserOrdersSchema.parse(data)
}
