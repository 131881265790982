import * as z from 'zod'

import { Decimal, invariant, Long } from '@x10/lib-core/utils'

export const zodDecimal = () => z.string().transform((value) => Decimal(value))
export const zodDecimalOrZero = () =>
  z
    .string()
    .default('0')
    .transform((value) => Decimal(value))

export const zodLong = () =>
  z.unknown().transform((value) => {
    // `value` is parsed as a `Long` or `number` by the `safeParse` util
    invariant(
      Long.isLong(value) || typeof value === 'number',
      '`value` must be `Long` or `number`',
    )

    return Long.isLong(value) ? value : Long(value)
  })
