import axios from 'axios'
import * as z from 'zod'

import { HttpHeader } from '@x10/lib-core/types'
import { invariant, isHexString, safeParse } from '@x10/lib-core/utils'

export const MarketNameSchema = z
  .string()
  .regex(/^[0-9A-Z]+-USD$/)
  .brand<'MarketName'>()
export const OrderTypeSchema = z.enum(['LIMIT', 'MARKET', 'CONDITIONAL', 'TPSL'])
export const OrderSideSchema = z.enum(['BUY', 'SELL'])
export const PositionSideSchema = z.enum(['LONG', 'SHORT'])
export const TradeTypeSchema = z.enum(['TRADE', 'LIQUIDATION', 'DELEVERAGE', 'ADL'])
export const OrderStatusSchema = z.enum([
  'UNKNOWN',
  'NEW',
  'UNTRIGGERED',
  'PARTIALLY_FILLED',
  'FILLED',
  'CANCELLED',
  'EXPIRED',
  'REJECTED',
])
export const HexStringSchema = z.string().transform((value) => {
  invariant(isHexString(value), '`value` must be a hex string')

  return value
})

export type ErrorResponse = {
  status: 'ERROR'
  error: {
    code: number
    message: string
  }
}
export type MarketName = z.infer<typeof MarketNameSchema>
export type OrderType = z.infer<typeof OrderTypeSchema>
export type OrderSide = z.infer<typeof OrderSideSchema>
export type OrderStatus = z.infer<typeof OrderStatusSchema>
export type PositionSide = z.infer<typeof PositionSideSchema>
export type TradeType = z.infer<typeof TradeTypeSchema>
export type CandlePriceSource = 'mark-prices' | 'index-prices' | 'trades'
export type X10Timeframe =
  | 'PT3H'
  | 'P1D'
  | 'P2D'
  | 'P4D'
  | 'P5D'
  | 'P1W'
  | 'P2W'
  | 'P1M'
  | 'P3M'
  | 'P6M'

export type X10Interval =
  | 'PT1M'
  | 'PT5M'
  | 'PT15M'
  | 'PT30M'
  | 'PT1H'
  | 'PT2H'
  | 'PT4H'
  | 'P1D'

/**
 * REST API requests are proxied by Next.js server on dev to avoid CORS
 * (see `next.config.js` -> `rewrites`)
 */
export const axiosClient = axios.create({
  paramsSerializer: {
    indexes: null,
  },
})

// Default implementation:
// https://github.com/axios/axios/blob/main/lib/defaults/index.js#L118-L140
axiosClient.defaults.transformResponse = [
  (data) => {
    if (!data) {
      return undefined
    }

    try {
      return safeParse(data)
    } catch {
      return undefined
    }
  },
]

export const withActiveAccount = (activeAccountId?: string) => ({
  [HttpHeader.ActiveAccount]: activeAccountId,
})
