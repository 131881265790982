import { axiosClient, withActiveAccount } from '@src/domain/api/x10/common'

import { EnvelopedApiKeySchema } from './api-key.schema'

export const createApiKey = async (args: { description: string }, accountId: string) => {
  const { data } = await axiosClient.post(
    '/api/v1/user/account/api-key',
    { description: args.description },
    {
      headers: withActiveAccount(accountId),
    },
  )

  return EnvelopedApiKeySchema.parse(data)
}
