import * as z from 'zod'

import { zodLong } from '@src/domain/api/utils/zod'
import { HexStringSchema } from '@src/domain/api/x10/common'

export const SubAccountSchema = z.object({
  accountIndex: z.number(),
  clientId: zodLong(),
  description: z.string(),
  id: zodLong(),
  l2Key: HexStringSchema,
  l2Vault: z.string().optional(),
  status: z.string(),
})

export type SubAccount = z.infer<typeof SubAccountSchema>
