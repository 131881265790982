import { create } from 'zustand'

import { getClientConfig } from '@src/domain/core/config/env'

export type FeatureName =
  | 'ACCOUNT_BLOCKING'
  | 'ACCOUNT_DEPOSIT_WITHDRAW_ACTIONS'
  | 'ACCOUNT_MENU_NAME_EDITING'
  | 'ACCOUNT_PROTECTION'
  | 'CLAIM_FUNDS'
  | 'MAKE_FIRST_DEPOSIT'
  | 'COMPETITION_CTAS'
  | 'DEBUG_INFO'
  | 'EDITABLE_TABLE_CELL'
  | 'HEADER_HELP'
  | 'HEADER_LANG_SELECTOR'
  | 'HEADER_MORE'
  | 'HEADER_NOTIFICATIONS'
  | 'HEADER_ONLINE'
  | 'HEADER_PORTFOLIO'
  | 'HEADER_REFERRALS'
  | 'HIDE_NOTIFICATIONS'
  | 'MAINNET_CLOSED'
  | 'MAINNET_REDIRECT_TO_NEW_URL'
  | 'MOBILE_HEADER_ITEMS'
  | 'MOBILE_WALLET_CONNECTION'
  | 'NEED_HELP_LINK'
  | 'ORDER_FORM_ALLOW_GOOD_TILL_DATE'
  | 'POSITION_TP_SL_ORDER_EDIT'
  | 'PRICE_CHART_SHOW_DISPLAY'
  | 'SHOW_TRADING_HISTORY_TABLES_MORE'
  | 'USER_MENU_DEPOSIT'
  | 'USER_MENU_FREE_TIER'
  | 'USER_MENU_NOTIFICATION_SETTINGS'
  | 'USER_MENU_PRIVACY_AND_SECURITY'

const clientConfig = getClientConfig()

const DEFAULT_FEATURES: Partial<Record<FeatureName, boolean>> = {
  ACCOUNT_BLOCKING: true,
  ACCOUNT_DEPOSIT_WITHDRAW_ACTIONS:
    clientConfig.isLocalOrDevRuntimeEnv || clientConfig.isProdRuntimeEnv,
  CLAIM_FUNDS: clientConfig.isTestnetRuntimeEnv,
  MAKE_FIRST_DEPOSIT:
    clientConfig.isLocalOrDevRuntimeEnv || clientConfig.isProdRuntimeEnv,
  HEADER_REFERRALS: !clientConfig.isTestnetRuntimeEnv,
  MAINNET_CLOSED: clientConfig.isProdRuntimeEnv,
  MAINNET_REDIRECT_TO_NEW_URL: true,
}

const filterActiveFeatures = (
  value: Partial<Record<FeatureName, boolean>>,
): FeatureName[] => {
  return Object.entries(value)
    .filter(([_featureName, featureValue]) => featureValue)
    .map(([featureName]) => featureName as FeatureName)
}

export const useFeaturesStore = create<{
  features: FeatureName[]
}>(() => ({
  features: filterActiveFeatures(DEFAULT_FEATURES),
}))

export const FeaturesStoreActions = {
  setFeature: (name: FeatureName, value: boolean) => {
    const features = useFeaturesStore.getState().features
    const nextFeatures = features.filter((feature) => feature !== name)

    if (value) {
      nextFeatures.push(name)
    }

    useFeaturesStore.setState({ features: nextFeatures })
  },

  reset: () => {
    useFeaturesStore.setState({ features: filterActiveFeatures(DEFAULT_FEATURES) })
  },
}
