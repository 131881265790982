import { axiosClient } from '../common'
import {
  EnvelopedLeaderboardCurrentUserSchema,
  EnvelopedLeaderboardTopUsersSchema,
  type EnvelopedLeaderboardCurrentUser,
  type EnvelopedLeaderboardTopUsers,
  type LeaderboardPeriod,
} from './leaderboard.schema'

export const getCurrentUser = async (args: {
  period: LeaderboardPeriod
}): Promise<EnvelopedLeaderboardCurrentUser> => {
  const { data } = await axiosClient.get('/api/v1/user/leaderboard', {
    params: { period: args.period },
  })
  return EnvelopedLeaderboardCurrentUserSchema.parse(data)
}

export const getTopUsers = async (args: {
  period: LeaderboardPeriod
}): Promise<EnvelopedLeaderboardTopUsers> => {
  const { data } = await axiosClient.get('/api/v1/info/leaderboard', {
    params: { period: args.period },
  })
  return EnvelopedLeaderboardTopUsersSchema.parse(data)
}
