import { compact, getSlotCompoundVariant, memo, splitProps } from '../helpers.js';
import { createRecipe } from './create-recipe.js';

const buttonDefaultVariants = {
  "visual": "primary-green",
  "size": "small"
}
const buttonCompoundVariants = []

const buttonSlotNames = [
  [
    "root",
    "button__root"
  ],
  [
    "iconLeftWrapper",
    "button__iconLeftWrapper"
  ]
]
const buttonSlotFns = /* @__PURE__ */ buttonSlotNames.map(([slotName, slotKey]) => [slotName, createRecipe(slotKey, buttonDefaultVariants, getSlotCompoundVariant(buttonCompoundVariants, slotName))])

const buttonFn = memo((props = {}) => {
  return Object.fromEntries(buttonSlotFns.map(([slotName, slotFn]) => [slotName, slotFn.recipeFn(props)]))
})

const buttonVariantKeys = [
  "visual",
  "size"
]
const getVariantProps = (variants) => ({ ...buttonDefaultVariants, ...compact(variants) })

export const button = /* @__PURE__ */ Object.assign(buttonFn, {
  __recipe__: false,
  __name__: 'button',
  raw: (props) => props,
  variantKeys: buttonVariantKeys,
  variantMap: {
  "visual": [
    "primary-green",
    "primary-red",
    "secondary-grey"
  ],
  "size": [
    "large",
    "small",
    "action"
  ]
},
  splitVariantProps(props) {
    return splitProps(props, buttonVariantKeys)
  },
  getVariantProps
})