import * as z from 'zod'

import { addRestEnvelope } from '@src/domain/api/utils/add-rest-envelope'
import { zodDecimal } from '@src/domain/api/utils/zod'
import { MarketNameSchema } from '@src/domain/api/x10/common'

const AccountLeverageSchema = z.object({
  market: MarketNameSchema,
  leverage: zodDecimal(),
})

export const EnvelopedAccountLeverageSchema = addRestEnvelope(
  z.array(AccountLeverageSchema),
)

export type AccountLeverage = z.infer<typeof AccountLeverageSchema>
