import * as z from 'zod'

import { addRestEnvelope } from '@src/domain/api/utils/add-rest-envelope'

const ApiKeySchema = z.object({
  key: z.string(),
})

export const EnvelopedApiKeySchema = addRestEnvelope(ApiKeySchema)
export type ApiKey = z.infer<typeof ApiKeySchema>
