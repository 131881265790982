import * as z from 'zod'

import { addRestEnvelope } from '@src/domain/api/utils/add-rest-envelope'
import { zodDecimal } from '@src/domain/api/utils/zod'

const ReferralCodeSchema = z.object({
  code: z.string(),
  isActive: z.boolean(),
})

const ReferralCheckpointSchema = z.object({
  threshold: zodDecimal(),
  maxReferrals: z.number(),
  codes: ReferralCodeSchema.array(),
})

const ReferralsSchema = z.object({
  volume: zodDecimal(),
  checkpoints: ReferralCheckpointSchema.array(),
})

export const EnvelopedReferralsSchema = addRestEnvelope(ReferralsSchema)
export type ReferralCheckpoint = z.infer<typeof ReferralCheckpointSchema>
export type ReferralCode = z.infer<typeof ReferralCodeSchema>
