import { STORAGE_KEY_PREFIX } from '@src/domain/core/utils/storage/get-storage-key'

export const withStorageKeyPrefix = (
  value:
    | { type: 'global' }
    | { type: 'client'; wallet: string | undefined }
    | { type: 'account'; id: string | undefined }
    | undefined,
) => {
  const basePrefix = `${STORAGE_KEY_PREFIX}-settings`

  if (!value) {
    return basePrefix
  }

  switch (value.type) {
    case 'global':
      return `${basePrefix}-global`
    case 'client':
      return value.wallet
        ? `${basePrefix}-client-${value.wallet}`
        : `${basePrefix}-client`
    case 'account':
      return value.id ? `${basePrefix}-account-${value.id}` : `${basePrefix}-account`
  }
}
