import { addDays } from 'date-fns'
import { create } from 'zustand'
import { createJSONStorage, persist } from 'zustand/middleware'

import { DEFAULT_LOCALE } from '@x10/lib-core/config'
import type { HexString, Locale } from '@x10/lib-core/types'
import { DEFAULT_COLOR_SCHEME } from '@x10/lib-ui-kit/config'
import type { ColorScheme } from '@x10/lib-ui-kit/types'

import { type MarketName, type X10Interval } from '@src/domain/api/x10/common'
import { DEFAULT_MARKET } from '@src/domain/core/config/static'
import { appLocalStorage } from '@src/domain/core/utils/storage/app-local-storage'
import { toMarketName } from '@src/domain/core/utils/to-market-name'

import { createAccountStorage } from './account-storage'
import { createClientStorage } from './client-storage'
import { globalStorage } from './global-storage'
import { withStorageKeyPrefix } from './utils'

const clientStorage = createClientStorage()
const accountStorage = createAccountStorage()

type GlobalSettingsStoreState = {
  locale: Locale
  colorScheme: ColorScheme
  marketName: MarketName
  chartInterval?: X10Interval
}

const DEFAULT_GLOBAL_SETTINGS: GlobalSettingsStoreState = {
  locale: DEFAULT_LOCALE,
  colorScheme: DEFAULT_COLOR_SCHEME,
  marketName: toMarketName(DEFAULT_MARKET),
}

export const useGlobalSettingsStore = create<
  GlobalSettingsStoreState & {
    setLocale(value: Locale): void
    setColorScheme(value: ColorScheme): void
    setMarketName(value: MarketName): void
    setChartInterval(value: X10Interval): void
    reset(): void
  }
>()(
  persist(
    (set) => ({
      ...DEFAULT_GLOBAL_SETTINGS,

      setLocale(value: Locale) {
        set({ locale: value })
      },

      setColorScheme(value: ColorScheme) {
        set({ colorScheme: value })
      },

      setMarketName(value: MarketName) {
        set({ marketName: value })
      },

      setChartInterval(value: X10Interval) {
        set({ chartInterval: value })
      },

      reset() {
        set(DEFAULT_GLOBAL_SETTINGS)
      },
    }),
    {
      name: withStorageKeyPrefix({ type: 'global' }),
      storage: createJSONStorage(() => globalStorage),
    },
  ),
)

export type AllowedNotificationsGroup =
  | 'ORDERS_AND_TRADES'
  | 'LIQUIDATIONS'
  | 'TX_AND_ACCOUNTS'

export const useClientSettingsStore = create<{
  // All notifications are allowed if undefined
  allowedNotifications?: AllowedNotificationsGroup[]
  rememberMe?: boolean
  rememberMeExpiryDate?: Date
  isAutoOpenedClaimFundsDialogClosedOnce: boolean
  isAutoOpenedDepositDialogClosedOnce: boolean
  setAllowedNotifications(value: AllowedNotificationsGroup[] | undefined): void
  setClientWallet(value: HexString | undefined): void
  setRememberMe(value: boolean): void
  closeAutoOpenedClaimFundsDialog(): void
  closeAutoOpenedDepositDialogDialog(): void
  setRememberMe(value: boolean): void
  setRememberMeExpiryDate(): void
  resetRememberMe(): void
}>()(
  persist(
    (set) => ({
      isAutoOpenedClaimFundsDialogClosedOnce: false,
      isAutoOpenedDepositDialogClosedOnce: false,
      closeAutoOpenedClaimFundsDialog() {
        set({ isAutoOpenedClaimFundsDialogClosedOnce: true })
      },
      closeAutoOpenedDepositDialogDialog() {
        set({ isAutoOpenedDepositDialogClosedOnce: true })
      },
      // we only reset rememberMe and rememberMeExpiryDate as sensitive
      // rest we want to keep to emulate as if we keep it on the backend
      resetRememberMe() {
        set({
          rememberMe: undefined,
          rememberMeExpiryDate: undefined,
        })
      },
      setAllowedNotifications(value: AllowedNotificationsGroup[] | undefined) {
        set({ allowedNotifications: value })
      },

      setRememberMe(value: boolean) {
        if (!value) {
          // remove rememberMeExpiryDate if rememberMe is false
          set({ rememberMeExpiryDate: undefined })
        }
        set({ rememberMe: value })
      },

      setRememberMeExpiryDate() {
        set({ rememberMeExpiryDate: addDays(new Date(), 7) })
      },

      setClientWallet(value: HexString | undefined) {
        clientStorage.setClientWallet(
          value ? (value.toLowerCase() as HexString) : undefined,
        )
        useClientSettingsStore.persist.rehydrate()
      },
    }),
    {
      name: withStorageKeyPrefix({ type: 'client', wallet: undefined }),
      storage: createJSONStorage(() => clientStorage.storage),
      skipHydration: true,
    },
  ),
)

export const useAccountSettingsStore = create<{
  // FIXME: Replace with actual settings
  test?: string
  setAccountId(value: string | undefined): void
}>()(
  persist(
    (_set) => ({
      setAccountId(value: string | undefined) {
        accountStorage.setAccountId(value)
        useAccountSettingsStore.persist.rehydrate()
      },
    }),
    {
      name: withStorageKeyPrefix({ type: 'account', id: undefined }),
      storage: createJSONStorage(() => accountStorage.storage),
      skipHydration: true,
    },
  ),
)

export const updateOrInitSettingsStorage = () => {
  const clientWallet = appLocalStorage.getItem<HexString>('client-wallet') ?? undefined
  const accountId = appLocalStorage.getItem<string>('account-id') ?? undefined

  useClientSettingsStore.getState().setClientWallet(clientWallet)
  useAccountSettingsStore.getState().setAccountId(clientWallet ? accountId : undefined)
}
