import * as z from 'zod'

import { addRestEnvelope } from '@src/domain/api/utils/add-rest-envelope'
import { zodDecimal, zodLong } from '@src/domain/api/utils/zod'
import { MarketNameSchema, PositionSideSchema } from '@src/domain/api/x10/common'

export const UserPositionSchema = z.object({
  id: zodLong(),
  accountId: z.number().positive(),
  market: MarketNameSchema,
  side: PositionSideSchema,
  leverage: zodDecimal(),
  size: zodDecimal(),
  value: zodDecimal(),
  openPrice: zodDecimal(),
  markPrice: zodDecimal(),
  liquidationPrice: zodDecimal().optional(),
  margin: zodDecimal(),
  unrealisedPnl: zodDecimal(),
  realisedPnl: zodDecimal(),
  tpTriggerPrice: zodDecimal().optional(),
  tpLimitPrice: zodDecimal().optional(),
  slTriggerPrice: zodDecimal().optional(),
  slLimitPrice: zodDecimal().optional(),
  adl: z.number().min(0).max(4).default(0),
  createdAt: z.number(),
  updatedAt: z.number(),
})

export const getPositionsSchema = addRestEnvelope(z.array(UserPositionSchema))

export type UserPositions = z.infer<typeof getPositionsSchema>
export type UserPosition = z.infer<typeof UserPositionSchema>
