import * as z from 'zod'

import { addRestEnvelope } from '@src/domain/api/utils/add-rest-envelope'
import { zodDecimal, zodLong } from '@src/domain/api/utils/zod'
import { HexStringSchema } from '@src/domain/api/x10/common'

const AssetOperationTypeSchema = z.enum([
  'CLAIM',
  'DEPOSIT',
  'SLOW_WITHDRAWAL',
  'FAST_WITHDRAWAL',
  'TRANSFER',
])
const AssetOperationStatusSchema = z.enum([
  'CREATED',
  'IN_PROGRESS',
  'REJECTED',
  'READY_FOR_CLAIM',
  'COMPLETED',
  'UNKNOWN',
])

const UserAssetOperationSchema = z.object({
  id: z.string(),
  type: AssetOperationTypeSchema,
  status: AssetOperationStatusSchema,
  amount: zodDecimal(),
  fee: zodDecimal(),
  asset: zodLong(),
  time: z.number(),
  accountId: zodLong(),

  // When operation type is `TRANSFER`
  counterpartyAccountId: z.optional(zodLong()),
  transactionHash: z.optional(HexStringSchema),
})

export type AssetOperationType = z.infer<typeof AssetOperationTypeSchema>
export type AssetOperationStatus = z.infer<typeof AssetOperationStatusSchema>
export type UserAssetOperation = z.infer<typeof UserAssetOperationSchema>
export const EnvelopedUserAssetOperationsSchema = addRestEnvelope(
  z.array(UserAssetOperationSchema),
)
