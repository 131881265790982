import { useCallback, useEffect } from 'react'
import { create } from 'zustand'

import { checkRequired } from '@x10/lib-core/utils'

import { useSuspendedCachedMarkets } from '@src/domain/api/hooks/markets-info/use-markets'
import type { CachedMarket } from '@src/domain/api/x10'
import { type MarketName } from '@src/domain/api/x10/common'
import { DEFAULT_MARKET } from '@src/domain/core/config/static'
import { useGlobalSettingsStore } from '@src/domain/core/store/user-settings'
import { toMarketName } from '@src/domain/core/utils/to-market-name'

import { NewOrderStoreActions } from '../ui/widgets/order-form/store/new-order'
import { useLatestTradesStore } from './latest-trades'

type InternalMarketsStoreState = {
  value?: {
    selectedMarket: CachedMarket
    allMarkets: CachedMarket[]
  }
}

const useInternalMarketsStore = create<InternalMarketsStoreState>(() => ({}))

const InternalMarketsStoreActions = {
  setValue(value: InternalMarketsStoreState['value']) {
    useInternalMarketsStore.setState({ value })
  },

  setSelectedMarket(market: CachedMarket) {
    const value = checkRequired(useInternalMarketsStore.getState().value, 'value')

    useInternalMarketsStore.setState({
      value: {
        ...value,
        selectedMarket: market,
      },
    })
  },
}

const findMarket = (
  markets: CachedMarket[],
  selectedMarketName: MarketName,
  /**
   * Used if selected market is not found (e.g. was disabled)
   */
  fallbackMarketName: MarketName,
): CachedMarket => {
  const selectedMarket = markets.find((market) => market.name === selectedMarketName)
  const result =
    selectedMarket ?? markets.find((market) => market.name === fallbackMarketName)

  return checkRequired(result, 'result')
}

export const useInitMarketStore = () => {
  const savedMarketName = useGlobalSettingsStore((state) => state.marketName)

  const { data: marketsCached } = useSuspendedCachedMarkets()

  useEffect(() => {
    const selectedMarket = findMarket(
      marketsCached,
      savedMarketName,
      toMarketName(DEFAULT_MARKET),
    )

    InternalMarketsStoreActions.setValue({
      selectedMarket,
      allMarkets: marketsCached,
    })
  }, [savedMarketName, marketsCached])
}

export const useMarketStoreIsReady = () => {
  return Boolean(useInternalMarketsStore((state) => state.value))
}

export const useSelectedMarket = () => {
  const selectedMarket = useInternalMarketsStore((state) => state.value?.selectedMarket)

  return checkRequired(selectedMarket, 'selectedMarket')
}

export const useAllMarkets = () => {
  const allMarkets = useInternalMarketsStore((state) => state.value?.allMarkets)

  return checkRequired(allMarkets, 'allMarkets')
}

export const useManageSelectedMarket = () => {
  return useCallback((market: CachedMarket) => {
    useLatestTradesStore.getState().reset()
    NewOrderStoreActions.reset({ type: 'market-changed' })

    InternalMarketsStoreActions.setSelectedMarket(market)
    useGlobalSettingsStore.setState({ marketName: market.name })
  }, [])
}
