import { type Decimal } from '@x10/lib-core/utils'

import {
  axiosClient,
  withActiveAccount,
  type MarketName,
} from '@src/domain/api/x10/common'

import { EnvelopedAccountLeverageSchema } from './leverage.schema'

export const getLeverage = async (
  args: { marketsNames: MarketName[] },
  accountId?: string,
) => {
  const { data } = await axiosClient.get<unknown>('/api/v1/user/leverage', {
    params: {
      market: args.marketsNames,
    },
    headers: withActiveAccount(accountId),
  })

  return EnvelopedAccountLeverageSchema.parse(data)
}

export const updateLeverage = async (
  args: {
    marketName: MarketName
    leverage: Decimal
  },
  accountId?: string,
) => {
  await axiosClient.patch<unknown>(
    '/api/v1/user/leverage',
    {
      market: args.marketName,
      leverage: args.leverage,
    },
    {
      headers: withActiveAccount(accountId),
    },
  )
}
