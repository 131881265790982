import type { HexString } from '@x10/lib-core/types'

import { axiosClient } from '@src/domain/api/x10/common'

import {
  InfoSchema,
  LoginSchema,
  LogoutSchema,
  RefreshSchema,
  RegisterSchema,
  WsTokenSchema,
  type Info,
} from './auth.schema'

export const login = async (args: {
  l1Signature: string
  time: Date
  rememberMe?: boolean
}) => {
  const { data } = await axiosClient.post<string>(
    '/auth/login',
    {
      l1Signature: args.l1Signature,
      login: {
        host: window.location.hostname,
        action: 'LOGIN',
        time: args.time.toISOString(),
      },
    },
    {
      params: {
        rememberMe: args.rememberMe,
      },
    },
  )

  return LoginSchema.parse(data)
}

export const logout = async () => {
  const { data } = await axiosClient.post<string>('/auth/logout')

  return LogoutSchema.parse(data)
}

export const register = async (args: {
  l1Signature: string
  l2Key: HexString
  l2Signature: {
    r: HexString
    s: HexString
  }
  accountCreation: {
    accountIndex: number
    wallet: string
    time: Date
  }
  referralCode?: string
  rememberMe?: boolean
}) => {
  const { data } = await axiosClient.post<string>(
    '/auth/register',
    {
      l1Signature: args.l1Signature,
      l2Key: args.l2Key,
      l2Signature: args.l2Signature,
      referralCode: args.referralCode,
      accountCreation: {
        accountIndex: args.accountCreation.accountIndex,
        wallet: args.accountCreation.wallet,
        tosAccepted: true,
        action: 'REGISTER',
        time: args.accountCreation.time.toISOString(),
      },
    },
    {
      params: {
        rememberMe: args.rememberMe,
      },
    },
  )

  return RegisterSchema.parse(data)
}

export const getWalletEligibility = async (args: { l1WalletAddress: string }) => {
  const { data } = await axiosClient.get<void>('/auth/register/eligible', {
    params: {
      l1WalletAddress: args.l1WalletAddress,
    },
  })

  return data
}

export const getInfo = async () => {
  const { data } = await axiosClient.get<Info>('/auth/info')
  return InfoSchema.parse(data)
}

export const refresh = async () => {
  const { data } = await axiosClient.get<string>('/auth/refresh')
  return RefreshSchema.parse(data)
}

export const getWsToken = async () => {
  const { data } = await axiosClient.get<Info>('/auth/wsToken')
  return WsTokenSchema.parse(data)
}
