import { axiosClient, type MarketName } from '@src/domain/api/x10/common'

import { EnvelopedFundingRatesSchema } from './funding-rates.schema'

export const getFundingRates = async (
  market: MarketName,
  startTime: number,
  endTime: number,
) => {
  const { data } = await axiosClient.get<unknown>(`/api/v1/info/${market}/funding`, {
    params: {
      startTime,
      endTime,
    },
  })

  return EnvelopedFundingRatesSchema.parse(data)
}
