import { keys } from 'lodash'
import type { StateStorage } from 'zustand/middleware'

import type { HexString } from '@x10/lib-core/types'
import { invariant } from '@x10/lib-core/utils'

import { withStorageKeyPrefix } from './utils'

const clearNonClientSettings = (clientWallet: string) => {
  const settingsKeys = keys(localStorage).filter((key) =>
    key.startsWith(withStorageKeyPrefix(undefined)),
  )
  const currClientKey = withStorageKeyPrefix({ type: 'client', wallet: clientWallet })

  for (const key of settingsKeys) {
    const isClientSettingsKey = key.startsWith(
      withStorageKeyPrefix({ type: 'client', wallet: undefined }),
    )

    if (isClientSettingsKey && key !== currClientKey) {
      settingsKeys.forEach((key) => {
        localStorage.removeItem(key)
      })

      return
    }
  }
}

export const createClientStorage = () => {
  let clientWallet: string | undefined = undefined

  const storage: StateStorage = {
    getItem: (name: string) => {
      invariant(clientWallet, 'clientWallet')

      return localStorage.getItem(`${name}-${clientWallet}`)
    },
    setItem: (name: string, value: string) => {
      invariant(clientWallet, 'clientWallet')

      localStorage.setItem(`${name}-${clientWallet}`, value)
    },
    removeItem: (name: string) => {
      invariant(clientWallet, 'clientWallet')

      localStorage.removeItem(`${name}-${clientWallet}`)
    },
  }

  return {
    storage,

    setClientWallet(id: HexString | undefined) {
      if (id) {
        clearNonClientSettings(id)
      }

      clientWallet = id
    },
  }
}
