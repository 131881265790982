import { axiosClient, withActiveAccount } from '@src/domain/api/x10/common'

import { EnvelopedAccountsInfoSchema } from './account-info.schema'

export const getAccounts = async (accountId?: string) => {
  const { data } = await axiosClient.get<unknown>('/api/v1/user/accounts', {
    headers: withActiveAccount(accountId),
  })

  return EnvelopedAccountsInfoSchema.parse(data)
}
