import * as z from 'zod'

import { addRestEnvelope } from '@src/domain/api/utils/add-rest-envelope'
import { zodDecimal, zodLong } from '@src/domain/api/utils/zod'
import { MarketNameSchema } from '@src/domain/api/x10/common'

const PositionSide = z.enum(['LONG', 'SHORT'])

const userFundingEntrySchema = z.object({
  id: zodLong(),
  accountId: zodLong(),
  market: MarketNameSchema,
  positionId: zodLong(),
  side: PositionSide,
  size: zodDecimal(),
  value: zodDecimal(),
  markPrice: zodDecimal(),
  fundingFee: zodDecimal(),
  fundingRate: zodDecimal(),
  paidTime: z.coerce.date(),
})

export const getFundingHistorySchema = addRestEnvelope(z.array(userFundingEntrySchema))

export type UserFunding = z.infer<typeof getFundingHistorySchema>
export type UserFundingEntry = z.infer<typeof userFundingEntrySchema>
