import { err, ok } from 'neverthrow'

import { type Long } from '@x10/lib-core/utils'

import { axiosClient } from '@src/domain/api/x10/common'
import { ApiErrorCode } from '@src/domain/core/errors/base'
import { parseError } from '@src/domain/core/errors/parse-error'
import type { StarkTransferSettlement } from '@src/domain/starkex/stark-transfer/stark-transfer-settlement'
import { type StarkWithdrawal } from '@src/domain/starkex/stark-witdrawal'

import {
  WithdrawalEnvelopedSchema,
  WithdrawalFeeEnvelopedSchema,
  WithdrawalRemainingLimitEnvelopedSchema,
  WithdrawalTargetEnvelopedSchema,
} from './withdrawal.schema'

export const withdrawal = async (
  transfer:
    | ({ type: 'SLOW_SELF' } & ReturnType<StarkWithdrawal['toJSON']>)
    | {
        type: 'FAST'
        amount: string
        accountId: Long
        asset: string
        transferFee: string
        settlement: ReturnType<StarkTransferSettlement['toJSON']>
      },
) => {
  const { data } = await axiosClient.post<unknown>('/api/v1/user/withdrawal', transfer)

  return WithdrawalEnvelopedSchema.parse(data)
}

export const getWithdrawalFee = async () => {
  try {
    const { data } = await axiosClient.get<unknown>('/api/v1/user/withdrawal/fast/price')
    // Note that this endpoint returns sometimes ERROR result with 200 (when fast withdrawal is disabled)
    const result = WithdrawalFeeEnvelopedSchema.parse(data)
    if (result.status === 'OK') {
      return ok(result)
    }
    if (result.error.code === ApiErrorCode.WithdrawalDisabled) {
      return err(result)
    }
    throw result
  } catch (e) {
    const error = parseError(e)
    if (error.code === ApiErrorCode.WithdrawalDisabled) {
      return err(error)
    }
    throw e
  }
}

export const getWithdrawalRemainingLimit = async ({ asset }: { asset: string }) => {
  const { data } = await axiosClient.get<unknown>(
    '/api/v1/user/withdrawal/fast/remaining',
    {
      params: { asset },
    },
  )
  return WithdrawalRemainingLimitEnvelopedSchema.parse(data)
}

export const getWithdrawalTarget = async () => {
  const { data } = await axiosClient.get<unknown>('/api/v1/user/withdrawal/fast/target')

  return WithdrawalTargetEnvelopedSchema.parse(data)
}
