import { useCallback } from 'react'
import { isNil } from 'lodash'

import { useFormatDecimal } from '@x10/lib-core/i18n'
import type { MarketAssetType } from '@x10/lib-core/types'
import { Decimal } from '@x10/lib-core/utils'

import type { MarketName } from '@src/domain/api/x10/common'

import { useGetCachedMarket } from './use-get-cached-market'

export const AssetPrecision = {
  AsIs: -1,
  VolumeStat: 0,
  OpenInterest: 0,
  TradingRules: 0,
  MarginSchedule: 0,
  UserOrTradeMetric: 2,
} as const

type DefaultFormattingOptions = {
  marketName?: MarketName
  showSymbol?: boolean
  showSign?: 'auto' | 'always' | 'exceptZero' | 'negative'
}

export const useFormatMarketAsset = (defaultOptions: DefaultFormattingOptions = {}) => {
  const formatDecimal = useFormatDecimal()
  const getCachedMarket = useGetCachedMarket()

  return useCallback(
    (
      value: {
        amount: number | Decimal | undefined | null
        type: MarketAssetType
      },
      {
        precision,
        marketName,
        showSymbol,
        showSign = 'negative',
      }: DefaultFormattingOptions & { precision?: number } = {},
    ) => {
      if (isNil(value.amount)) {
        return undefined
      }

      const { assets } = getCachedMarket(marketName ?? defaultOptions.marketName)

      const { code: assetCode, precision: assetPrecision } =
        value.type === 'collateral' ? assets.collateral : assets.synthetic

      const fracDigits =
        precision === AssetPrecision.AsIs
          ? Decimal(value.amount).getDecimalPlaces()
          : (precision ?? assetPrecision.getDecimalPlaces())
      const formattedValue = formatDecimal(value.amount, {
        signDisplay: showSign,
        minimumFractionDigits: fracDigits,
        maximumFractionDigits: fracDigits,
      })

      if (showSymbol ?? defaultOptions.showSymbol) {
        return `${formattedValue} ${assetCode}`
      }

      return formattedValue
    },
    [
      defaultOptions.marketName,
      defaultOptions.showSymbol,
      formatDecimal,
      getCachedMarket,
    ],
  )
}
