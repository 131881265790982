import {
  axiosClient,
  withActiveAccount,
  type MarketName,
  type PositionSide,
} from '@src/domain/api/x10/common'

import { getFundingHistorySchema } from './funding.schema'

export const getFundingHistory = async (
  {
    marketsNames,
    side,
    startTime,
  }: { marketsNames?: MarketName[]; side?: PositionSide; startTime?: number } = {},
  accountId?: string,
) => {
  const { data } = await axiosClient.get('/api/v1/user/funding/history', {
    params: {
      market: marketsNames,
      side,
      startTime,
    },
    headers: withActiveAccount(accountId),
  })

  return getFundingHistorySchema.parse(data)
}
