import * as z from 'zod'

import { addRestEnvelope } from '@src/domain/api/utils/add-rest-envelope'
import { zodLong } from '@src/domain/api/utils/zod'

const ClaimSchema = z.object({
  id: zodLong(),
  accountId: zodLong(),
  clientId: zodLong(),
  status: z.enum(['CREATED', 'PROCESSED', 'REJECTED']),
})

const ClaimResponseSchema = z.object({
  id: zodLong(),
})

const NextClaimSchema = z.number()

export const EnvelopedClaimResponseSchema = addRestEnvelope(ClaimResponseSchema)
export const EnvelopedClaimSchema = addRestEnvelope(ClaimSchema)
export const EnvelopedNextClaimSchema = addRestEnvelope(NextClaimSchema)

export type Claim = z.infer<typeof ClaimSchema>
export type NextClaim = z.infer<typeof NextClaimSchema>
export type ClaimResponse = z.infer<typeof ClaimResponseSchema>
