import { z } from 'zod'

import { Long } from '@x10/lib-core/utils'

import {
  addRestEnvelope,
  addRestEnvelopeCombined,
} from '@src/domain/api/utils/add-rest-envelope'
import { zodDecimal, zodLong } from '@src/domain/api/utils/zod'
import { HexStringSchema } from '@src/domain/api/x10/common'

// This is ID after /withdrawal POST request
const WithdrawalSchema = zodLong()
const WithdrawalFeeSchema = zodDecimal()
const WithdrawalRemainingLimit = zodDecimal()
const WithdrawalTarget = z.object({
  l2Vault: z.string().transform((value) => Long(value)),
  l2Key: HexStringSchema,
})

export const WithdrawalEnvelopedSchema = addRestEnvelope(WithdrawalSchema)
export const WithdrawalFeeEnvelopedSchema = addRestEnvelopeCombined(WithdrawalFeeSchema)
export const WithdrawalRemainingLimitEnvelopedSchema = addRestEnvelope(
  WithdrawalRemainingLimit,
)
export const WithdrawalTargetEnvelopedSchema = addRestEnvelope(WithdrawalTarget)

export type Withdrawal = z.infer<typeof WithdrawalSchema>
