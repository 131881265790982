import { route, stringParser } from 'typesafe-routes'

import { HOME_PAGE_URL } from '@x10/lib-core/config'
import type { HexString } from '@x10/lib-core/types'

import { getClientConfig } from '@src/domain/core/config/env'

const makeHomeRoute = () => {
  const trade = route('trade', {}, {})

  const portfolio = route(
    'portfolio',
    {},
    {
      overview: route('overview', {}, {}),
      positions: route('positions', {}, {}),
      orders: route('orders', {}, {}),
      realisedPnl: route('realised-pnl', {}, {}),
      trades: route('trades', {}, {}),
      funding: route('funding', {}, {}),
      transfers: route('transfers', {}, {}),
    },
  )

  const referrals = route('referrals', {}, {})
  const leaderboard = route('leaderboard', {}, {})

  const more = route(
    '',
    {},
    {
      apiManagement: route('api-management', {}, {}),
      documentation: route('documentation', {}, {}),
      news: route('news', {}, {}),
      communityLinks: route('community-links', {}, {}),
      statistics: route('statistics', {}, {}),
      techStatus: route('tech-status', {}, {}),
    },
  )

  const help = route(
    'help',
    {},
    {
      helpCenter: route(
        'help-center',
        {},
        {
          leverage: route('leverage', {}, {}),
          deposit: route('deposit', {}, {}),
          withdraw: route('withdraw', {}, {}),
          claimFunds: route('claimFunds', {}, {}),
          transfer: route('transfer', {}, {}),
        },
      ),
    },
  )

  return route(
    HOME_PAGE_URL,
    {},
    {
      trade,
      portfolio,
      referrals,
      leaderboard,
      more,
      markets: route('markets', {}, {}),
      help,
    },
  )
}

export const homeRoute = makeHomeRoute()

export const tradingViewRoute = route(
  'www.tradingview.com/x/:snapshotId',
  {
    snapshotId: stringParser,
  },
  {},
)

export const documentationRoute = route(
  'docs.x10.exchange/x10-resources',
  {},
  {
    trading: route(
      'trading',
      {},
      {
        fundingPayments: route('funding-payments', {}, {}),
        marginSchedule: route('margin-schedule', {}, {}),
        accountsAndMargin: route('trading-accounts-and-margin', {}, {}),
        liquidationAndBankruptcyPrices: route(
          'liquidation-logic#liquidation-and-bankruptcy-prices',
          {},
          {},
        ),
        autoDeleveraging: route('liquidation-logic#auto-deleveraging-adl', {}, {}),
        orderCost: route('order-cost', {}, {}),
      },
    ),
    legal: route(
      'legal',
      {},
      {
        termsOfUse: route('terms-of-use', {}, {}),
        statementOfRisk: route('statement-of-risk', {}, {}),
        restrictedCountries: route('restricted-countries', {}, {}),
        privacyPolicy: route('privacy-policy', {}, {}),
      },
    ),
    accountOperations: route(
      'account-operations',
      {},
      {
        deposits: route('deposits-and-withdrawals#deposits', {}, {}),
        withdrawals: route('deposits-and-withdrawals#withdrawals', {}, {}),
        accountCreation: route('account-creation', {}, {}),
      },
    ),
    testnet: route(
      'testnet',
      {},
      {
        claimingCollateral: route('claiming-collateral', {}, {}),
        tradingCompetitions: route('trading-competitions', {}, {}),
      },
    ),
  },
)

export const etherscanRoute = (
  value: { type: 'address'; value: HexString } | { type: 'tx'; value: HexString },
) => {
  const domain = getClientConfig().isProdRuntimeEnv
    ? 'etherscan.io'
    : 'sepolia.etherscan.io'

  return `https://${domain}/${value.type}/${value.value}`
}

export const asExternalRoute = (url: string) => `https://${url}`
