import { axiosClient } from '@src/domain/api/x10/common'

import { EnvelopedAssetsSchema } from './assets.schema'

export const getAssets = async ({
  assetsNames,
  isCollateral,
}: { assetsNames?: string[]; isCollateral?: boolean } = {}) => {
  const { data } = await axiosClient.get<unknown>('/api/v1/info/assets', {
    params: {
      asset: assetsNames,
      collateral: isCollateral,
    },
  })

  return EnvelopedAssetsSchema.parse(data)
}
