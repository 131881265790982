import * as z from 'zod'

import { addRestEnvelope } from '@src/domain/api/utils/add-rest-envelope'
import { zodDecimal, zodLong } from '@src/domain/api/utils/zod'

import { MarketNameSchema, OrderSideSchema, TradeTypeSchema } from '../../common'

export const AccountTradeSchema = z.object({
  id: zodLong(),
  accountId: z.number(),
  market: MarketNameSchema,
  orderId: zodLong(),
  side: OrderSideSchema,
  price: zodDecimal(),
  qty: zodDecimal(),
  value: zodDecimal(),
  fee: zodDecimal(),
  isTaker: z.boolean(),
  tradeType: TradeTypeSchema,
  createdTime: z.number(),
})

export const EnvelopedAccountTradesSchema = addRestEnvelope(z.array(AccountTradeSchema))

export type EnvelopedAccountTrades = z.infer<typeof EnvelopedAccountTradesSchema>
export type AccountTrade = z.infer<typeof AccountTradeSchema>
