import * as z from 'zod'

import { addRestEnvelope } from '@src/domain/api/utils/add-rest-envelope'
import { zodLong } from '@src/domain/api/utils/zod'
import { HexStringSchema } from '@src/domain/api/x10/common'

const ClientInfoSchema = z.object({
  id: zodLong(),
  status: z.enum(['ACTIVE', 'BLOCKED']),
  l1WalletAddress: HexStringSchema,
})

export const EnvelopedClientInfoSchema = addRestEnvelope(ClientInfoSchema)

export type ClientInfo = z.infer<typeof ClientInfoSchema>
