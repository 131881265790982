import * as z from 'zod'

import { addRestEnvelope } from '@src/domain/api/utils/add-rest-envelope'
import { HexStringSchema } from '@src/domain/api/x10/common'

const SettingsSchema = z.object({
  starkExContractAddress: HexStringSchema,
})

export const EnvelopedSettingsSchema = addRestEnvelope(SettingsSchema)
