import * as z from 'zod'

import type { CryptoCurrencyCode } from '@x10/lib-core/config'

import { addRestEnvelope } from '@src/domain/api/utils/add-rest-envelope'
import { zodDecimal } from '@src/domain/api/utils/zod'

export const BalanceSchema = z.object({
  collateralName: z.string().transform((value) => value as CryptoCurrencyCode),
  balance: zodDecimal(),
  equity: zodDecimal(),
  status: z.enum(['UNKNOWN', 'ACTIVE', 'LIQUIDATING', 'MARGIN_CALL']),
  availableForTrade: zodDecimal(),
  availableForWithdrawal: zodDecimal(),
  unrealisedPnl: zodDecimal(),
  initialMargin: zodDecimal(),
  marginRatio: zodDecimal(),
  updatedTime: z.number(),
})

export const EnvelopedBalanceSchema = addRestEnvelope(BalanceSchema)

export type Balance = z.infer<typeof BalanceSchema>
export type EnvelopedBalance = z.infer<typeof EnvelopedBalanceSchema>
