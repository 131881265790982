import { axiosClient, withActiveAccount } from '@src/domain/api/x10/common'

import { EnvelopedReferralsSchema } from './referrals.schema'

export const getReferrals = async (accountId?: string) => {
  const { data } = await axiosClient.get<unknown>('/api/v1/user/referrals', {
    headers: withActiveAccount(accountId),
  })

  return EnvelopedReferralsSchema.parse(data)
}
