import * as z from 'zod'

import { zodLong } from '@src/domain/api/utils/zod'

export const RestEnvelopeOk = <T extends z.Schema>(data: T) =>
  z.object({
    status: z.literal('OK'),
    data,
    pagination: z
      .object({
        cursor: zodLong().optional(),
        count: z.number(),
      })
      .optional(),
  })

export const RestEnvelopeError = z.object({
  status: z.literal('ERROR'),
  error: z.object({
    code: z.number(),
    message: z.string(),
  }),
})

export const addRestEnvelope = <T extends z.Schema>(data: T) => {
  return RestEnvelopeOk(data)
}

export const addRestEnvelopeCombined = <T extends z.Schema>(data: T) => {
  return z.discriminatedUnion('status', [RestEnvelopeOk(data), RestEnvelopeError])
}
