import * as z from 'zod'

import { addRestEnvelope } from '@src/domain/api/utils/add-rest-envelope'
import { zodDecimal } from '@src/domain/api/utils/zod'
import { MarketNameSchema } from '@src/domain/api/x10/common'

const FundingRateSchema = z
  .object({
    m: MarketNameSchema,
    f: zodDecimal(),
    T: z.number(),
  })
  .transform((value) => ({
    market: value.m,
    fundingRate: value.f,
    timestamp: value.T,
  }))

export const EnvelopedFundingRatesSchema = addRestEnvelope(FundingRateSchema.array())

export type FundingRate = z.infer<typeof FundingRateSchema>
