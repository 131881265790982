import * as z from 'zod'

import { addRestEnvelope } from '@src/domain/api/utils/add-rest-envelope'
import { zodLong } from '@src/domain/api/utils/zod'
import { HexStringSchema } from '@src/domain/api/x10/common'

const AccountInfoSchema = z.object({
  accountId: zodLong(),
  description: z.string(),
  accountIndex: z.number(),
  status: z.string(),
  l2Key: HexStringSchema,
  l2Vault: z.string(),
  apiKeys: z.string().array(),
})

export const EnvelopedAccountInfoSchema = addRestEnvelope(AccountInfoSchema)
export const EnvelopedAccountsInfoSchema = addRestEnvelope(AccountInfoSchema.array())

export type AccountInfo = z.infer<typeof AccountInfoSchema>
