import { getLogger, invariant, type Long } from '@x10/lib-core/utils'

import {
  axiosClient,
  withActiveAccount,
  type MarketName,
} from '@src/domain/api/x10/common'

import { EnvelopedPlacedOrderSchema } from './orders.schema'

const LOGGER = getLogger('api.x10.trading.orderManagement.orders')

export const placeOrder = async (args: { order: object }, accountId?: string) => {
  invariant('id' in args.order, 'Order must have an `id`')

  LOGGER.debug('Placing an order: id=%s', args.order.id)

  const { data } = await axiosClient.post<unknown>('/api/v1/user/order', args.order, {
    headers: withActiveAccount(accountId),
  })

  return EnvelopedPlacedOrderSchema.parse(data)
}

export const cancelOrder = async (args: { orderId: Long }, accountId?: string) => {
  LOGGER.debug('Cancelling an order: id=%s', args.orderId)

  await axiosClient.delete<unknown>(`/api/v1/user/order/${args.orderId}`, {
    headers: withActiveAccount(accountId),
  })
}

export type MassCancelOrderArgs = {
  orderIds?: Long[]
  externalOrderIds?: string[]
  markets?: MarketName[]
  cancelAll?: boolean
}

export const massCancelOrder = async (args: MassCancelOrderArgs, accountId?: string) => {
  await axiosClient.post<unknown>('/api/v1/user/order/massCancel', args, {
    headers: withActiveAccount(accountId),
  })
}
