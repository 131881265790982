import * as z from 'zod'

import { addRestEnvelope } from '@src/domain/api/utils/add-rest-envelope'
import { zodLong } from '@src/domain/api/utils/zod'

export const EnvelopedPlacedOrderSchema = addRestEnvelope(
  z.object({
    id: zodLong(),
    externalId: z.string(),
  }),
)
