import * as z from 'zod'

import { addRestEnvelope } from '@src/domain/api/utils/add-rest-envelope'
import { zodDecimal } from '@src/domain/api/utils/zod'

const ReferralsConfigThresholdSchema = z.object({
  volume: zodDecimal(),
  maxReferrals: z.number(),
})

const ReferralsConfigSchema = z.object({
  thresholds: ReferralsConfigThresholdSchema.array(),
})

export const EnvelopedReferralsConfigSchema = addRestEnvelope(ReferralsConfigSchema)
