import {
  axiosClient,
  withActiveAccount,
  type MarketName,
  type OrderType,
} from '@src/domain/api/x10/common'

import { EnvelopedAccountTradesSchema } from './trades.schema'

export type TradesArgs = {
  marketsNames?: MarketName[]
  orderType?: OrderType
}

export const getTrades = async (args: TradesArgs = {}, accountId?: string) => {
  const { data } = await axiosClient.get<unknown>('/api/v1/user/trades', {
    params: {
      market: args.marketsNames,
      orderType: args.orderType,
    },
    headers: withActiveAccount(accountId),
  })

  return EnvelopedAccountTradesSchema.parse(data)
}
