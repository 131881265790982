import { useCallback, useMemo } from 'react'
import { keyBy } from 'lodash'

import { checkRequired } from '@x10/lib-core/utils'

import type { CachedMarket } from '@src/domain/api/x10'
import type { MarketName } from '@src/domain/api/x10/common'
import { useAllMarkets, useSelectedMarket } from '@src/domain/trade/store/market'

export const useGetCachedMarket = () => {
  const { name: selectedMarketName } = useSelectedMarket()
  const allMarkets = useAllMarkets()

  const marketsHash = useMemo(() => {
    return keyBy(allMarkets, 'name')
  }, [allMarkets])

  return useCallback(
    (marketName?: MarketName): CachedMarket => {
      return checkRequired(marketsHash[marketName ?? selectedMarketName], 'market')
    },
    [selectedMarketName, marketsHash],
  )
}
