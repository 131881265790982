import { z } from 'zod'

import { addRestEnvelope } from '@src/domain/api/utils/add-rest-envelope'
import { zodDecimal, zodLong } from '@src/domain/api/utils/zod'

const WithdrawalStatusSchema = z.enum([
  'CREATED',
  'REJECTED',
  'IN_PROGRESS',
  'READY_FOR_CLAIM',
  'COMPLETED',
])

export const WithdrawalSchema = z.object({
  id: zodLong(),
  amount: zodDecimal(),
  assetId: zodLong(),
  accountId: zodLong(),
  createdAt: z.number(),
  description: z.string().optional(),
  settlement: z.any(),
  status: WithdrawalStatusSchema,
  rejectReason: z.string().optional(),
})

export const WithdrawalsEnvelopedSchema = addRestEnvelope(WithdrawalSchema.array())

export type Withdrawal = z.infer<typeof WithdrawalSchema>
