import { axiosClient, withActiveAccount } from '@src/domain/api/x10/common'

import { EnvelopedAccountInfoSchema } from './account-info.schema'

export const getAccountInfo = async (accountId?: string) => {
  const { data } = await axiosClient.get<unknown>('/api/v1/user/account/info', {
    headers: withActiveAccount(accountId),
  })

  return EnvelopedAccountInfoSchema.parse(data)
}
